@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-BoldItalic.woff2') format('woff2'),
        url('../../fonts/Nexa-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-BookItalic.woff2') format('woff2'),
        url('../../fonts/Nexa-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-Bold.woff2') format('woff2'),
        url('../../fonts/Nexa-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-Heavy.woff2') format('woff2'),
        url('../../fonts/Nexa-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-Black.woff2') format('woff2'),
        url('../../fonts/Nexa-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-LightItalic.woff2') format('woff2'),
        url('../../fonts/Nexa-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-XBold.woff2') format('woff2'),
        url('../../fonts/Nexa-XBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-Book.woff2') format('woff2'),
        url('../../fonts/Nexa-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-BlackItalic.woff2') format('woff2'),
        url('../../fonts/Nexa-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-Thin.woff2') format('woff2'),
        url('../../fonts/Nexa-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-XBoldItalic.woff2') format('woff2'),
        url('../../fonts/Nexa-XBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-ThinItalic.woff2') format('woff2'),
        url('../../fonts/Nexa-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-Light.woff2') format('woff2'),
        url('../../fonts/Nexa-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-HeavyItalic.woff2') format('woff2'),
        url('../../fonts/Nexa-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-Regular.woff2') format('woff2'),
        url('../../fonts/Nexa-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/Nexa-RegularItalic.woff2') format('woff2'),
        url('../../fonts/Nexa-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/NexaBold.woff2') format('woff2'),
        url('../../fonts/NexaBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nexa';
    src: url('../../fonts/NexaLight.woff2') format('woff2'),
        url('../../fonts/NexaLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}




@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap');
/*font-family: 'Raleway', sans-serif;*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
/*font-family: 'Poppins', sans-serif;*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
/*font-family: 'Roboto', sans-serif;*/

@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@300;400;500;600;700;800;900&display=swap');
/*font-family: 'Barlow Semi Condensed', sans-serif;*/

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*font-family: 'Montserrat', sans-serif;*/


@import url('https://fonts.googleapis.com/css2?family=Train+One&display=swap');

/*font-family: 'Train One', cursive;*/

@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*font-family: 'Montserrat Alternates', sans-serif;*/



/*html {scroll-behavior: smooth;}*/
/*Rj Code*/

::selection {
    background:#0096C7;
    color:#FFF;
}

::-moz-selection {
    background:#0096C7;
    color:#FFF;
}

/*html {scroll-behavior: smooth;}*/
select::-ms-expand { display: none; }
img{max-width:100%;height:auto;}
a{  -webkit-transition: all .3s ease;-moz-transition: all .3s ease;-o-transition: all .3s ease;-ms-transition: all .3s ease;transition: all .3s ease;}
h1 {margin:0px 0px 30px 0px;padding:0px;line-height: 1.2;font-weight:600;}
h2 {margin:0px 0px 25px 0px;padding:0px;line-height: 1.2;font-weight:600;}
h3 {margin:0px 0px 25px 0px;padding:0px;line-height: 46px;font-weight:600;}
h4 {margin:0px 0px 25px 0px;padding:0px;line-height: 1.2;font-weight:600;}
h5 {margin:0px 0px 15px 0px;line-height: 25px;font-weight:600;}
h6 {margin:0px 0px 15px 0px;line-height: 1.1;font-weight:normal;}



.spacer1px {clear:both;line-height:0;font-size:0;overflow:hidden;height:1px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer5px {clear:both;line-height:0;font-size:0;overflow:hidden;height:5px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer15px {clear:both;line-height:0;font-size:0;overflow:hidden;height:15px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer10px {clear:both;line-height:0;font-size:0;overflow:hidden;height:10px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer20px {clear:both;line-height:0;font-size:0;overflow:hidden;height:20px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer30px {clear:both;line-height:0;font-size:0;overflow:hidden;height:30px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer50px {clear:both;line-height:0;font-size:0;overflow:hidden;height:50px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer80px {clear:both;line-height:0;font-size:0;overflow:hidden;height:50px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}

body {
    overflow-x:hidden;
    color:#333333;
    font-size:16px;
    line-height:1.4; 
    margin:0px; 
    padding:0px; 
    outline:none;
    text-decoration:none;
    list-style:none;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    background-color:#fff;
    /*height: 100vh;*/
}

p{line-height:1.4;margin:0px 0px 20px 0px;font-family: 'Poppins', sans-serif;}
ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}
a:hover{ text-decoration:none;}
-webkit-input-placeholder {color: #a1a1a1 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
-moz-placeholder {color: #a1a1a1 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
-ms-input-placeholder {color: #a1a1a1 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
-moz-placeholder {color: #a1a1a1 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
.form-control::-webkit-input-placeholder {color: #a1a1a1 !important; opacity:1 !important;}
.form-control::-moz-placeholder {color: #a1a1a1 !important; opacity:1 !important;}
.form-control:-ms-input-placeholder {color: #a1a1a1 !important; opacity:1 !important;}
.form-control:-moz-placeholder {color: #a1a1a1 !important; opacity:1 !important;}

:focus, button:focus {outline-style:none;box-shadow:none;border-color:transparent;}
*:focus {outline: none;}
input:focus{outline:none;}

.pagetitle{ position:relative; text-align:left; padding:0px 0px 70px 0px; }
.pagetitle h2{line-height: 1.1; font-size:51px;color:#fff;padding:0px; margin:0px 0px 0px 0px;}

img{max-width:100%;height:auto; outline:none;}
a{outline:none; border:none;}
.fl_w{float: left;}
/*.container-fluid{padding-left: 60px;padding-right: 60px;}*/


.limited_time_sec{position: relative;padding: 20px 0 20px 0;}
.limited_time_sec_main{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 20px;align-items: center;}
.limited_time_sec_left{position: relative;}
.limited_time_sec_left_head{position: relative;margin-bottom: 20px;}
.limited_time_sec_left_head h5{font-weight: 600;font-size: 26px;color: #006BD7;margin-bottom: 5px;position: relative;list-style: inherit;}
.limited_time_sec_left_head p{font-weight: 600;font-size: 22px;color: #000;margin-bottom: 0px;position: relative;}
.limited_time_sec_left_body{position: relative;}
.limited_time_sec_left_body_inner{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-column-gap: 15px;}
.limited_time_sec_left_single{position: relative;margin-bottom: 15px;}
.limited_time_sec_left_single label{position: relative;display: block;font-weight: 500;font-size: 18px;color: #000000;margin-bottom: 5px;}
.limited_time_sec_left_single input{position: relative;width: 100%;height: 45px;background: #F8F8F8;
border: 1px solid #C1C1C1;border-radius: 8px;font-weight: 400;font-size: 16px;color: #333;padding-left: 12px;}
.limited_time_sec_left_single textarea{position: relative;width: 100%;*height: 45px;background: #F8F8F8;
border: 1px solid #C1C1C1;border-radius: 8px;font-weight: 400;font-size: 16px;color: #878787;padding-left: 12px;padding-top: 12px;}
.limited_time_sec_left_single input::-webkit-input-placeholder {color: #878787 !important; opacity:1 !important;}
.limited_time_sec_left_single input::-moz-placeholder {color: #878787 !important; opacity:1 !important;}
.limited_time_sec_left_single input:-ms-input-placeholder {color: #878787 !important; opacity:1 !important;}
.limited_time_sec_left_single input:-moz-placeholder {color: #878787 !important; opacity:1 !important;}
.new_mobile_photo .iti__selected-flag { height: 100%;background: #EDEDED;border-radius: 5px 0px 0px 5px;}
.new_mobile_photo .iti--separate-dial-code .iti__selected-dial-code {margin-left: 6px;font-size: 15px;  color: #202020;}
.new_mobile_photo .iti__arrow{border-top: 6px solid #202020;}
.new_mobile_photo.limited_time_sec_left_single input{padding-left: 100px;} 
.claim_offer_btn_sec{position: relative;text-align: right;width: 100%;}
.claim_offer_btn_sec button{position: relative;display: inline-block;font-weight: 500;font-size: 16px;color: #FFFFFF;border: none;;padding: 6px 38px;border-radius: 6px;background: #2196F3;}
.limited_time_sec_right{position: relative;}
.limited_time_sec_right_head {position: relative;margin-bottom: 10px;display: flex;align-items: center;
    justify-content: space-between;}
.limited_time_sec_right_head h4 { position: relative;font-weight: 500;font-size: 30px;color: #000000;
    margin: 0px;line-height: inherit;}
.limited_time_sec_right_inner {position: relative;background: #E1F2FF;border-radius: 15px; padding: 35px 25px;*height: 430px;}
.limited_time_sec_right_inner ul {display: block;}
.limited_time_sec_right_inner ul li { display: block;margin-bottom: 25px;position: relative;font-size: 18px; padding-left: 40px;font-weight: 500;}
.limited_time_sec_right_inner ul li:last-child {margin-bottom: 0px;}
.limited_time_sec_right_inner ul li:before {position: absolute;content: '';
    width: 24px; height: 24px;left: 0; top: 0;background: url(../../images/check-bg-icn-blue.png);}

.Professional_photo_shoot_sec{position: relative;padding: 30px 0 20px 0;}
.Professional_photo_shoot_sec_inner{position: relative;*display: grid;*grid-template-columns: 1fr 1fr;*grid-gap: 70px;align-items: center;}
.Professional_photo_shoot_sec_inner .row{align-items: center;}
.Professional_photo_shoot_sec_inner_left{position: relative;}
.Professional_photo_shoot_sec_inner_left h6{position: relative;font-weight: 400;font-size: 50px;color: #1F74BA;list-style: inherit;margin: 0 0 20px 0;}
.Professional_photo_shoot_sec_inner_left p{font-weight: 400;position: relative;font-size: 22px;line-height: inherit;margin: 0px;color: #000000;margin: 0 0 30px 0;width: 410px;}
.share_page_btn{position: relative;display: inline-block;font-weight: 500;font-size: 18px;line-height: inherit;text-align: center;color: #FFFFFF;padding: 5px 20px;background: #1F74BA;border-radius: 6px;border: 1px solid #1F74BA;}
.share_page_btn:hover{color: #1F74BA;background: #fff;}
.upper_arrow{position: absolute;width: 280px;top: -70px;right: -40px}
.prd_head{position: relative;display: flex;align-items: center;column-gap: 20px;;margin: 0 0 25px 0;}
.prd_head h5{position: relative;font-weight: 700;font-size: 46px;color: #FFFFFF;width: 250px;height: 70px;background: #1F74BA;display: flex;align-items: center;justify-content: center;margin: 0px;}
.prd_head h5:after{content: '';position: absolute;background: #000;left: 6px;top: 6px;height: 100%;width: 100%;z-index: -1;}
.prd_head h4{position: relative;font-weight: 700;font-size: 46px;color: #1F74BA;margin: 0px;}
.Professional_photo_shoot_sec_inner_right{position: relative;}
.Professional_photo_shoot_sec_inner_right_slide{position: relative;}
.Professional_photo_shoot_sec_inner_right_slide_single{position: relative;width: 70%;margin: 0 0 auto auto;right: 30px;margin-bottom: 40px;}
.Professional_photo_shoot_sec_inner_right_slide_single img{width: 100%;object-fit: cover;*border-radius: 30px;}
.Professional_photo_shoot_sec_inner_right_slide_single:after{content: '';position: absolute;left: 25px;top: 25px;width: 100%;height: 100%;background: #A9D7FD;border-radius: 30px;z-index: -1;}

#photo_slide .owl-stage-outer{*height: 560px !important;height: auto;}
.Professional_photo_shoot_sec_inner_right_slide_inner{position: relative;}
.wave_slide1 { position: absolute;top: 5px;height: auto; right: 30px; z-index: 111;width: 130px;*width: 20%;}
.wave_slide2 {position: absolute;bottom: 10px;width: 105px; height: auto;right: 55%;z-index: 111;}
.get_your_products{position: absolute; display: flex;align-items: flex-start; right: -45%;bottom: -5px;z-index: 11;*transform: rotate(17.2deg);}
.get_your_products img{width: 60px;}
.get_your_products span{position: relative;display: block;background: linear-gradient(180deg, #FD0C0C 0%, #2196F3 100%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;background-clip: text;
text-fill-color: transparent;font-weight: 500;font-size: 24px;line-height: 36px;text-align: center;}
.get_your_products_div{position: relative;width: 330px;height: 158px;border: 2px solid #616161;
    border-radius: 20px; display: flex;align-items: center;justify-content: center;background-color: #fff;}

.shoots_for_sec_new{position: relative;padding: 20px 0;}
.shoots_for_sec_new_head{position: relative;text-align: center;margin-bottom: 30px;}
.shoots_for_sec_new_head h6{position: relative;font-weight: 500;font-size: 30px;color: #000000;margin: 0px;}
.shoots_for_sec_new_body{position: relative;display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;grid-gap: 20px;width: 80%;margin: 0 auto;align-items: center;}
.shoots_for_sec_new_single{position: relative;text-align: center;}
.shoots_for_sec_new_single img{width: auto;}

.category_sec_new_design{position: relative;padding: 20px 0;}
.category_sec_new_design_inner{position: relative;}
.category_sec_new_design_body{position: relative;display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;grid-gap: 60px;}
.category_sec_new_design_body_single{position: relative;}
.category_sec_new_design_body_single img{width: 100%;border-radius: 10px;object-fit: cover;}
.category_sec_new_design_body_single_img{position: relative;}
.category_sec_new_design_body_single_img:after{content: '';position: absolute;left:10px;top:10px;width: 100%;height: 100%;background: #A9D7FD;border-radius: 10px;z-index: -1;}
.category_sec_new_design_body_single_cont{position: relative;margin: 30px 0 0 0;text-align: center;width: 100%;}
.category_sec_new_design_body_single_cont h5{position: relative;font-weight: 500;font-size: 18px;color: #000000;margin: 0px;}
.wave_cate_left{position: absolute;left: -60px;top: -60px;z-index: -1;width: 250px;}
.wave_cate_right{position: absolute;right: -60px;top: -80px;z-index: -1;width: 180px;}
.happy_clients_sec{position: relative;padding: 20px 0 40px 0;}
.happy_clients_sec_main{position: relative;}
.happy_clients_sec_main_body{position: relative;display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;grid-gap: 70px;}
.happy_clients_sec_main_body_single{position: relative;text-align: center;}
.happy_clients_sec_main_body_single_img{position: relative;}
.happy_clients_sec_main_body_single_img img{width: 100%;height: 100%;object-fit: cover;border-radius: 10px;}
.cate_play_icn{width: 60px !important;height: 60px !important;position: absolute; top: 50%;left: 0;right: 0;margin: 0 auto;transform: translateY(-50%);}
.happy_clients_sec_main_body_single_img:after{content: '';position: absolute;left:10px;top:10px;width: 100%;height: 100%;background: #A9D7FD;border-radius: 10px;z-index: -1;}    
.happy_clients_sec_main_body_single_cont{position: relative;margin: 30px 0 0 0;text-align: center;}
.happy_clients_sec_main_body_single_cont h6{position: relative;font-weight: 600;font-size: 18px;color: #000000;margin-bottom: 5px;}
.happy_clients_sec_main_body_single_cont p{position: relative;font-weight: 400;font-size: 16px;color: #000000;margin-bottom: 0px;}

.wave_cate_right1{position: absolute;right: -45px;top: 50%;z-index: -1;width: 180px;transform: translateY(-50%);}
.wave_cate_left1{position: absolute;left: -60px;top: 50%;z-index: -1;width: 150px;transform: translateY(-50%);}

.hide_desk{display: none;}
.hide_mob{display: flex;}

.new_mobile_photo .iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container{top: 0px !important;}


.save_draft_sec_new{position: relative;}    
.save_draft_sec_new .modal-body{padding: 0px;}
.save_draft_sec_new .modal-dialog{background: #FDFDFD;max-width: 600px;margin: 15px auto;background: #FFFFFF;box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);border-radius: 10px;}
.save_draft_sec_inner{position: relative;padding: 60px 0;text-align: center;width: 80%;margin: 0 auto;}
.save_draft_sec_inner .close{position: absolute; right: -10px;top: 30px;color: #000; font-size: 22px; font-weight: 600;opacity: 1;color: #000000;}
.save_draft_sec_inner img{width: auto;margin-bottom: 40px;}
.save_draft_sec_inner h6{position: relative;font-weight: 600; font-size: 27px;color: #000000;
    border-bottom: 1px solid rgba(112 112 112 / 0.09);padding-bottom: 30px;margin-bottom: 30px;}
.share_link_sec{display: block;margin-bottom: 25px;}    
.share_link_sec li{margin-right: 10px;display: inline-block;}
.share_link_sec li:last-child{margin-right: 0px;}
.sha_text{font-weight: 600;font-size: 16px;color: #090909;}
.share_link_sec li a img{margin-bottom: 0px;width: 100%;}
.or_sec_new{display: block;width: 100%;position: relative;margin-bottom: 25px;font-weight: 600;
font-size: 16px;color: #090909;}
.or_sec_new:before{position: absolute; left: 90px;width: 25%; height: 1px;background: rgb(112 112 112 / 19%);content: '';top: 11px;}
.or_sec_new:after{position: absolute; right: 90px;width: 25%; height: 1px;background: rgb(112 112 112 / 19%);content: '';top: 11px;}
.copy_link_sec_new {position: relative;}
.copy_link_sec_new h5{position: relative;text-align: left;font-weight: 600;font-size: 18px;color: #090909;margin-bottom: 10px;}
.copy_link_sec_new span{position: relative;}
.copy_link_sec_new span input{position: relative;width: 100%;border: 1px solid #DBDBDB;height: 50px;padding-left: 50px;font-weight: 400;font-size: 16px;color: #ABAAAA;}
.copy_link_sec_new span i{position: absolute;left: 15px;top: 4px;font-size: 18px;color: #000;
    z-index: 11;}
.copy_link_sec_new span a{position: absolute; right: 6px;top: -8px; z-index: 11;background: #1B74D2;
    font-weight: 400; font-size: 16px;color: #FFFFFF;padding: 8px 25px;border-radius: 4px;}


.otp_popup_sec_new{position: relative;}    
.otp_popup_sec_new .modal-body{padding: 0px;}

.otp_popup_sec_new .modal-dialog{background: #FDFDFD;max-width: 630px;margin: 15px auto;background: #FFFFFF;box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);border-radius: 10px;}
.otp_popup_sec_inner{position: relative;padding: 15px;width: 100%;}
.otp_popup_sec_inner .close{position: absolute; right: 10px;top: 10px;color: #000; font-size: 20px; font-weight: 600;opacity: 1;color: #000000;}
.otp_popup_sec_inner_head{position: relative;}
.otp_popup_sec_inner_head h5{position: relative;font-weight: 500;font-size: 18px;color: #000000;margin-bottom: 15px;padding-bottom: 12px;border-bottom: 1px solid #C8C8C8;}
.check_circle_sec {position: relative;display: flex;font-weight: 500;font-size: 15px;color: #2C9A1A;
    padding: 8px;align-items: center;justify-content: center;background: #F3FFF2;border: 2px solid #2C9A1A;
    border-radius: 4px;width: 100%; margin: 0 auto 30px;}
.check_circle_sec i{font-size: 22px;font-weight: 600 !important;margin-right: 8px;}
.enter_otp_sec_new{position: relative;}
.enter_otp_sec_new h6{position: relative;font-weight: 500;font-size: 16px;letter-spacing: 0.05em;color: #000000;}
.enter_otp_sec_new_inner{position: relative;display: grid;grid-template-columns: repeat(6,1fr);grid-gap: 25px;width: 50%;}
.enter_otp_sec_new_inner input{position: relative;width: 100%;height: 45px;color: #929292;font-size: 25px;
    border: none;border-bottom: 2px solid #121212;text-align: center;}
.enter_otp_sec_new_inner_reset{position: relative;display: flex;align-items: center;justify-content: space-between;width: 50%;margin: 15px 0 0 0;}  
.enter_otp_sec_new_inner_reset a{position: relative;display: inline-block;font-weight: 500;font-size: 10px;color: #000;}  
.enter_otp_sec_new_inner_reset a.disa{position: relative;display: inline-block;font-weight: 500;font-size: 12px;color: #BABABA;}
.enter_otp_sec_new_inner_reset span{position: relative;font-weight: 400;font-size: 12px;color: #000000;display: inline-block;}
.enter_otp_sec_new_inner_reset p{margin: 0px;display: inline-block;padding-left: 5px;}
.yes_agree_sec{position: relative;margin: 15px 0 0 0;}
.enter_otp_sec_new_inner_reset button.disa{position: relative;display: inline-block;font-weight: 500;font-size: 12px;color: #BABABA;}
.enter_otp_sec_new_inner_reset button{position: relative;display: inline-block;font-weight: 500;font-size: 12px;color: #BABABA;}


.yes_agree_sec .container {display: inline-block;position: relative;padding-left: 30px;margin-bottom: 0px;cursor: pointer;font-size: 14px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none; font-weight: 300;color: #000;margin-left: 0;width: auto;}
.yes_agree_sec .container b{color: #007AFF;}
.yes_agree_sec .container input {position: absolute;opacity: 0;cursor: pointer;height: 0;width: 0;}
.yes_agree_sec .checkmark {position: absolute; top: 0; left: 0; height: 18px; width: 18px;background-color: #EAEAEA; border: 1px solid #818181;border-radius: 2px;}
.yes_agree_sec .container:hover input ~ .checkmark {background-color: #EAEAEA;}
.yes_agree_sec .container input:checked ~ .checkmark {background-color: #2196F3;border:1px solid #2196F3;}
.yes_agree_sec .checkmark:after {content: "\f00c"; position: absolute;display: none;left: 2px;
    top: 0px;font-family: 'Font Awesome 5 Pro';color: #fff;font-size: 12px;}
.yes_agree_sec .container input:checked ~ .checkmark:after {display: block;}
.submit_btn_new_otp{position: relative;text-align: center;margin: 30px 0 0 0;}
.submit_btn_new_otp button{position: relative;display: inline-block;background: #2196F3;border-radius: 6px;font-weight: 500;font-size: 16px;color: #FFFFFF;padding: 6px 38px;border: none;}
.submit_btn_new_otp a{position: relative;display: inline-block;background: #2196F3;border-radius: 6px;font-weight: 500;font-size: 16px;color: #FFFFFF;padding: 6px 38px;border: none;}
.disable_btn{background-color: #CACACA !important;color: #fff !important;}
#otp_verification_submit_btn{position: relative;display: inline-block;background: #3b99ff;padding: 8px 32px;
    color: #fff;border-radius: 5px;font-size: 16px;font-weight: 500;}


.singers_sec{position: relative;}
.singers_sec .Professional_photo_shoot_sec_inner_left h6{margin: 0px;}
.singers_sec .prd_head h4{font-weight: 500;}
.singers_sec .Professional_photo_shoot_sec_inner_left p{margin-bottom: 15px;width: 600px;font-weight: 500;}
.singers_sec .Professional_photo_shoot_sec_inner_left h1{font-weight: 400;position: relative;font-size: 17px;line-height: inherit;margin: 0px;color: #000000;margin: 0 0 25px 0;width: 450px;display: block;}
.singers_sec .get_your_products span{font-size: 20px;}
.singers_sec .limited_time_sec_left_head h5{color: #000000;}
.singers_sec .limited_time_sec_left_head h5 span{color: #2196F3;}


.features_singers_sec_new{position: relative;padding: 20px 0;background: url(../../images/singers-bg.png);background-size: cover;background-repeat: no-repeat;background-position: center center;}
.features_singers_sec_new_inner_sec{position: relative;}
.features_singers_sec_new_head{position: relative;text-align: center;margin-bottom: 25px;}
.features_singers_sec_new_head h6{position: relative;font-weight: 500;font-size: 30px;color: #000000;}
.features_singers_sec_inner{position: relative;display: grid;grid-template-columns: repeat(5,1fr);grid-gap: 25px;}
.features_singers_sec_inner_single{position: relative;background: #FFFFFF;border: 0.636978px solid #D1D1D1;box-shadow: 0px 4.96532px 4.96532px rgba(0, 0, 0, 0.2);border-radius: 12.8992px;padding: 15px;}
.features_singers_sec_inner_single_img{position: relative;margin-bottom: 10px;}
.features_singers_sec_inner_single_img img{width: 100%;border-radius: 6px;}
.features_singers_sec_inner_single_img_cont{position: relative;}
.features_singers_sec_inner_single_img_cont h5{position: relative;font-weight: 600;font-size: 18px;color: #000000;margin-bottom: 4px;}
.features_singers_sec_inner_single_img_cont h5 img{width: 60px;margin-left: 5px;}
.features_singers_sec_inner_single_img_cont ul{display: block;margin-bottom: 8px;}
.features_singers_sec_inner_single_img_cont ul li{display: inline-block;margin-right: 5px;font-weight: 500;font-size: 13px;color: #000000;position: relative;}
.features_singers_sec_inner_single_img_cont ul li:last-child{margin-right: 0px;}
.features_singers_sec_inner_single_img_cont ul li:after{position: absolute;content: '';right: -5px;top: 0;width: 1px;height: 100%;background: #000;}
.features_singers_sec_inner_single_img_cont ul li:last-child:after{display: none;}
.features_singers_sec_inner_single_img_cont h6{position: relative;margin-bottom: 0px;font-size: 13px;color: #000000;}

.comedy_sec{position: relative;}
.comedy_sec .Professional_photo_shoot_sec_inner_left h6{margin-bottom: 10px;}
.comedy_sec .prd_head h4{font-weight: 500;}
.comedy_sec .prd_head{margin-top: 15px;}
.comedy_sec .get_your_products span{font-size: 21px;}


.inquiry_sec_new{position: relative;}    
.inquiry_sec_new .modal-body{padding: 0px;}

.inquiry_sec_new .modal-dialog{background: #FDFDFD;max-width: 700px;margin: 15px auto;background: #FFFFFF;box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);border-radius: 10px;}
.inquiry_sec_new_inner{position: relative;padding: 25px 15px;width: 100%;text-align: center;}
.inquiry_sec_new_inner .close{position: absolute; right: 10px;top: 10px;color: #000; font-size: 20px; font-weight: 600;opacity: 1;color: #000000;z-index: 11;}
.inquiry_sec_new_inner img{width: 117px;margin: 0 auto 20px;}
.inquiry_sec_new_inner p{position: relative;margin-bottom: 0px;font-weight: 500;font-size: 20px;color: #000000;}


/*Upgrade Popup Column Wise*/

.upgrade_prem_plan_sec_new{position: relative;margin-top: 20px;display: grid;grid-template-columns: 1fr 1fr 1fr;grid-gap: 0px;border-bottom: 1px solid #ccc;}
.upgrade_prem_left h6{position: relative;font-size: 18px;font-weight: 600;color: #000;margin-bottom: 20px;    height: 45px;}
.jobs_list{display: block;}
.jobs_list li{display: block;padding-bottom: 25px;font-size: 14px;font-weight: 400;color: #000;}
.upgrade_prem_left_head {position: relative;margin-bottom: 25px;}
.upgrade_prem_left_head h5{position: relative;margin-bottom: 0px;font-weight: 500;font-size: 26px;color: #000000;height: 45px;display: flex;align-items: center;justify-content: center;border-bottom: 1px solid #ccc;}
.upgrade_prem_left_head h5 img{width: 40%;}
.upgrade_prem_left_head h5.text-left{justify-content: flex-start !important;border-bottom: none !important;}
.upgrade_prem_middle{position: relative;border-right: 1px solid #ccc;}
.upgrade_prem_middle h6{position: relative;font-size: 18px;font-weight: 600;color: #000;margin-bottom: 20px;text-align: center;height: 45px;}
.upgrade_prem_right{position: relative;border-right: 1px solid #ccc;}
.upgrade_prem_right h6{position: relative;font-size: 18px;font-weight: 600;color: #000;margin-bottom: 20px;text-align: center;}
.upgrade_prem_right h6 span{display: block;margin: 5px 0 0 0;font-size: 14px;font-weight: 400;}
.upgrade_prem_left{position: relative;border-right: 1px solid #ccc;}


.Professional_photo_shoot_sec_inner_right_slide_single .fa-play {position: absolute;top: 50%;left: 0;
    right: 0;text-align: center;font-size: 60px;color: rgba(255 255 255 / 0.70);font-weight: 900 !important;transform: translateY(-50%);}
.see_more_btn_sec{position: relative;margin: 30px 0 0 0;text-align: center;}    
.see_more_btn_sec a{position: relative;display: inline-block;background: #2196F3;border-radius: 6px;font-weight: 500;font-size: 16px;color: #FFFFFF;padding: 6px 30px;border: none;}
.see_more_btn_sec button{position: relative;display: inline-block;background: #2196F3;border-radius: 6px;font-weight: 500;font-size: 16px;color: #FFFFFF;padding: 6px 30px;border: none;}

#photo_slide .owl-nav{display: flex;}
#photo_slide .owl-nav i{display: none;}
#photo_slide .owl-next{background: url(../../images/arrow-slide.png);background-repeat: no-repeat;background-size: cover; height: 66px;width: 66px;right: -15px; position: absolute;top: 50%;transform: translateY(-50%);}
#photo_slide .owl-prev{display: none;}
.new_pos_wave{right: 0px;top: 15px;width: 120px;}

.our_team_sec_new_prod{position: relative;padding: 15px 0;}
.our_team_sec_new_prod_inner{position: relative;}
.our_team_sec_new_prod_inner_head{position: relative;text-align: center;}
.our_team_sec_new_prod_inner_head h6{position: relative;color: #000000;font-size: 30px;font-weight: 600;}
.our_team_sec_new_prod_inner_head:after{position: absolute;content: '';left: 0;width: 410px;height: 3px;right: 0;    bottom: -15px;background: #2196F3;margin: 0 auto;}
.our_team_body_sec_new{position: relative;}




/*Product shoot page*/

.shoot_land_bg{position: relative;*background: #fff url(../../images/bg-color.png);*height:100vh;width: 100%;background-size: cover;background-repeat: no-repeat;background-position: center center;}

.logo_land_sec_new{position: relative;padding: 20px 0 5px 0;}
.logo_land_sec_new_inner{position: relative;}
.logo_land_sec_new_inner img{width: 200px;}

.unevil_sec_new_des{position: relative;padding: 10px 0 20px 0;}
.unevil_sec_new_des:before{position: absolute;content: '';background: url(../../images/up-blue-img.png);
    width: 863px;height: 1075px; background-repeat: no-repeat;background-position: center center;background-size: cover;top: -80px;left: 0;z-index: -1;}
.unevil_sec_new_des:after{position: absolute;content: '';background: url(../../images/red-blue-img.png);
    width: 900px;height: 1010px; background-repeat: no-repeat;background-position: center center;background-size: cover;top: -80px;right: 0px;z-index: -1;}    
.unevil_sec_new_des_inner{position: relative;}
.unevil_sec_new_des_inner_head{position: relative;text-align: center;width: 65%;margin: 0 auto 40px;}
.unevil_sec_new_des_inner_head h6{position: relative;margin-bottom: 0px;color: #055FA7;font-weight: 600;font-size: 36px;line-height: 44px;font-family: 'Montserrat', sans-serif;animation: zoomIn;}
.unevil_sec_new_des_inner_body{position: relative;display: flex;align-items: center;gap:15px;justify-content: center;}
.unevil_center_box{position: relative;}
.unevil_center_box img{width: 100%;object-fit: cover;*width: auto;*border-radius: 20px;}
.unevil_middle_box{position: relative;display: flex;*align-items: center;gap:15px;flex-direction: column;}
.icn_abso{position: absolute;width: 220px !important;top: -50px;right: -70%;z-index: -1;}
.icn_abso_end{position: absolute;width: 122px !important;bottom: 50px;z-index: -1;}
.pos_icn_center{position: absolute;width: 160px !important;z-index: 11;top: -150px;left: 40px;height: 160px;}
.review_sec_new_list{position: relative;margin-top: 20px;}
.review_sec_new_list_single{position: relative;}
.review_sec_new_list_single p{position: relative;color: #000000;font-size: 14px;font-weight: 500;width: 380px;left: 50px;}
.review_sec_new_list_single p:before{position: absolute;content: '';background: url(../../images/rev-icn-left.png);background-size: 23px;width: 23px;height: 21px;background-repeat: no-repeat;background-position: center center;
    top: -20px;left: -25px;animation-name: swing;}
.review_sec_new_list_single p:after{position: absolute;content: '';background: url(../../images/rev-icn-right.png);background-size: 23px;width: 23px;height: 21px;background-repeat: no-repeat;background-position: center center;
    right: 10px;bottom: -10px;animation: swing;}
.get_start_btn{position: absolute;left: 0;right: 0;bottom: 0;margin: 0 auto;color: #FFFFFF;font-size: 20px;font-weight: 600; background: #005CA7;border-radius: 50px;width: 230px; height: 50px;display: flex;align-items: center;justify-content: center;}
.get_start_btn:hover{color: #fff;}    

.product_shoot_sec_cate{position: relative;padding: 20px 0;}
.product_shoot_sec_cate_inner{position: relative;}
.product_shoot_sec_cate_inner_head{position: relative;text-align: center;margin-bottom: 25px;}
.product_shoot_sec_cate_inner_head h6{position: relative;margin-bottom: 0px;color: #000000;font-size: 40px;font-weight: 600;font-family: 'Montserrat', sans-serif;}
.product_shoot_sec_cate_inner_body{position: relative;display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;grid-gap: 12px;}
.product_shoot_sec_cate_inner_body_single{position: relative;}
.product_shoot_sec_cate_inner_body_single img{width: 100%;border-radius: 20px;object-fit: cover;}
.product_single_cont{position: absolute;left: 15px;bottom: 15px;z-index: 11;right: 0;margin: 0 auto;width: 100%}
.product_single_cont h5{position: relative;color: #FFFFFF;font-size: 22px;font-weight: 600;margin-bottom: 0px;}

.budget_sec_new_sec{position: relative;padding: 50px 0 20px 0;}
.budget_sec_new_sec_inner{position: relative;}
.budget_sec_new_sec_inner_body{position: relative;display: flex;align-items: center;height:100px;border-radius: 25px;background: #FFF1F2;width: 100%;}
.budget_sec_new_sec_inner_body ul{display: flex;align-items: center;justify-content: space-between;width: 100%;padding: 0 50px;}
.budget_sec_new_sec_inner_body ul li{position: relative;color: #444444;font-size: 25px;font-weight: 500;padding-left: 25px;}
.budget_sec_new_sec_inner_body ul li:before{position: absolute;content: '';left: 0;top: 7px;height: 18px;width: 18px;background: #E77878;border-radius: 50%;}
.budget_sec_new_sec .product_shoot_sec_cate_inner_head{margin-bottom: 35px;}


.shhots_for_sec_new{position: relative;padding: 25px 0;}
.shhots_for_sec_new_inner{position: relative;}
.shhots_for_sec_new_inner_body{position: relative;display: flex;align-items: center;gap:80px;justify-content: center;margin-bottom: 40px;}
.shhots_for_sec_single{position: relative;}
.shhots_for_sec_new .product_shoot_sec_cate_inner_head{margin-bottom: 40px;}
.budget_sec_new_btn{position: relative;text-align: center;}
.budget_sec_new_btn a{position: relative;display: inline-block;background: #0760A7;border-radius: 30px;color: #fff;font-size: 20px;font-weight: 500;padding: 7px 35px;}
.new_happy{position: relative;}
.new_happy:before{position: absolute;content: '';background: url(../../images/happy-img.png);background-position: center;background-repeat: no-repeat;background-size: cover;height: 350px;width: 350px;left: -40px;top: -40px;z-index: -1;}
.new_happy .shoots_for_sec_new_head h6{font-size: 40px;font-family: 'Montserrat', sans-serif;font-weight: 600;}
.new_happy .budget_sec_new_btn{margin-top: 40px;}

.limited_time_only{position: relative;padding: 30px 0;}
.limited_time_only:after {position: absolute;content: '';background: url(../../images/limited-img.png);
    height: 400px; width: 400px;background-repeat: no-repeat;background-size: cover;right: 0;top: -88px;
    z-index: -1;background-position: right;}
.limited_time_only_inner{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 40px;align-items: center;}
.limited_time_only_left_head{position: relative;margin-bottom: 40px;}
.limited_time_only_left_head h6{position: relative;color: #1F74BA;font-size: 38px;font-weight: 600;margin-bottom: 12px;}
.limited_time_only_left_head p{position: relative;margin: 0px;color: #000000;font-size: 24px;font-weight: 500;}
.limited_time_only_left_body{position: relative;}
.limited_time_only_left{position: relative;}
.limited_time_only_left_grid{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-column-gap: 40px;}
.limited_time_only_left_single{position: relative;margin-bottom: 50px;}
.limited_time_only_left_single input{position: relative;width: 100%;height: 30px;color: #ABABAB;border: none;border-bottom: 1px solid #030204;font-size: 16px;font-weight: 400;padding-bottom: 12px;}
.limited_time_only_left_single input::-webkit-input-placeholder {color: #ABABAB !important; opacity:1 !important;}
.limited_time_only_left_single input::-moz-placeholder {color: #ABABAB !important; opacity:1 !important;}
.limited_time_only_left_single input:-ms-input-placeholder {color: #ABABAB !important; opacity:1 !important;}
.limited_time_only_left_single input:-moz-placeholder {color: #ABABAB !important; opacity:1 !important;}

.limited_time_only_left_single textarea{position: relative;width: 100%;color: #ABABAB;border: none;border-bottom: 1px solid #030204;font-size: 16px;font-weight: 400;padding-bottom: 12px;}
.submit_btn_limited{position: relative;display: flex;align-items: center;justify-content: flex-end;}
.submit_btn_limited a{position: relative;display: inline-block;color: #FFFFFF;font-size: 16px;font-weight: 500;background: #0760A7;border-radius: 20px;padding: 7px 35px;}
.submit_btn_limited button{position: relative;display: inline-block;color: #FFFFFF;font-size: 16px;font-weight: 500;background: #0760A7;border-radius: 20px;padding: 7px 35px;border: none;}
.limited_time_only_right{position: relative;margin: 0;left: 100px;}
.limited_time_only_right img{width: auto;object-fit: cover;}
.lim_upper{position: absolute;right: 130px;top: -40px;}
.lim_down{position: absolute;left: -65px;bottom: -100px;}

.photoshoot_inceludes{position: relative;padding: 30px 0;}
.photoshoot_inceludes_inner{position: relative;}
.photoshoot_inceludes_single{position: relative;display: grid;grid-template-columns: 1fr 1fr;grid-gap: 30px;}
.photoshoot_inceludes_single_left{position: relative;}
.photoshoot_inceludes_single_left h5{position: relative;display: flex;align-items: center;gap:20px;color: #353535;font-size: 36px;font-weight: 600;margin-bottom: 20px;}
.photoshoot_inceludes_single_left_box{position: relative;background: #FFFFFF;border-radius: 40px;padding:25px;box-shadow: 1px 0px 5px 2px #f4f4f4;}
.photoshoot_inceludes_single_left_box ul{display: block;}
.photoshoot_inceludes_single_left_box ul li{display: block;margin: 0 0 12px 0;position: relative;color: #000000;font-weight: 400;font-size: 20px;padding-left: 35px;}
.photoshoot_inceludes_single_left_box ul li:last-child{margin-bottom: 0px;}
.photoshoot_inceludes_single_left_box ul li::before{content: '\f058';position: absolute;left: 0;top: 0;color: #FF6874;font-family: 'FONT AWESOME 5 PRO';font-weight: 600;font-size: 18px;}
.photoshoot_inceludes_single_right{position: relative;}
.photoshoot_inceludes_single_right img{width: auto;object-fit: cover;}
.vanity_img{position: absolute;left: 0;bottom: -200px;}
.grp_img{position: absolute;left: 230px;top: 50px;}
.new_align_box{position: relative;align-items: flex-end;top: 135px;}
.right_box{position: relative;}
.photoshoot_inceludes_single_left_box.right_box:after{position: absolute;content: '';background: url(../../images/ornament-img.png);background-position: center;background-repeat: no-repeat;background-size: cover;width: 93px;height: 43px;right: 0;bottom: -20px;}
.right_box ul li::before{color: #1E98A9;}
.cup_tea {position: absolute; right: 80px;top: -120px;}
.shoe_img {position: absolute;bottom: 0;left: 50%;}
.new_btn_place{position: relative;margin-top: 150px;}
.photoshoot_inceludes:before{position: absolute;content: '';background: url(../../images/left-box-img.png);
    background-position: center;background-attachment: scroll;background-repeat: no-repeat; width: 40px;
    height: 250px;left: 0;top: 20%;}
.photoshoot_inceludes:after{position: absolute;content: '';background: url(../../images/right-box-img.png);
    background-position: center;background-attachment: scroll;background-repeat: no-repeat;width: 45px;
    height: 192px;right: 0;top: 0%;}  

.photoshoot_inceludes_inner:before{position: absolute;content: '';background: url(../../images/pss-1.png);
    width: 100%;height: 100%;background-repeat: no-repeat;background-position: center center;background-size: cover;top: 0px;left: -50%;filter: blur(6px);z-index: -1;}
.photoshoot_inceludes_inner:after{position: absolute;content: '';background: url(../../images/pss-2.png);
    width: 100%; height: 100%;background-repeat: no-repeat; background-position: center center;
    background-size: cover; top: 0px; right: -50%; filter: blur(6px);z-index: -1;}

.desk_ver{display: flex;}    
.mob_ver{display: none;}   


.get_prdoct_rot{position: relative;height: 105px;width: 105px;background: #D4ECFF;border: 2px solid #FFFFFF;display: flex;align-items: center;justify-content: center;border-radius: 50%;margin: 0 auto;}
.get_prdoct_rot img{width: 68px;}
.rota_img { position: absolute; width: 170px !important;top: -25px; left: 0; -webkit-animation:spinn 6s linear infinite;-moz-animation:spinn 6s linear infinite;animation:spinn 6s linear infinite;}
@-moz-keyframes spinn { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spinn { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spinn { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.e_comm_sec_new{position: relative;}
.e_comm_sec_new_inner{position: relative;padding: 40px 50px;display: flex;align-items: center;justify-content: center;}
.e_comm_sec_new .modal-body{padding: 0px;}
.e_comm_sec_new .modal-dialog {max-width: 700px; height: 450px;border: 1px solid #888888;border-radius: 40px;
    background: linear-gradient(0deg, rgba(93, 93, 93, 0.4), rgba(93, 93, 93, 0.4)), linear-gradient(0deg, #888888, #888888);box-shadow: 46.53333282470703px -46.53333282470703px 46.53333282470703px 0px #1C1C1C1A inset;margin: 50px auto 0;}
.e_comm_sec_new_inner .close{width: 30px; height: 31px;position: absolute;right: 20px;top: 15px;opacity: 1;
    z-index: 111;}
.e_comm_sec_new_inner_slide{position: relative;}
.e_comm_sec_new_inner_slide_single{position: relative;width: 90%;margin: 0 auto;}
.e_comm_sec_new_inner_slide_single img{width: 100%;}
.e_comm_sec_new_inner_slide .owl-nav{position: absolute;top: 50%;left: -28px;transform: translateY(-50%);
    z-index: 11;display: flex;align-items: center;justify-content: space-between;width: 110%;}
.e_comm_sec_new_inner_slide .owl-next i{display: none;} 
.e_comm_sec_new_inner_slide .owl-prev i{display: none;}
.e_comm_sec_new_inner_slide .owl-prev{position: relative;background: url(../../images/prev-img.png) !important;background-position: center !important;background-repeat: no-repeat !important;background-size: cover !important;width: 24px;height: 42px;}
.e_comm_sec_new_inner_slide .owl-next{position: relative;background: url(../../images/next-img.png) !important;background-position: center !important;background-repeat: no-repeat !important;background-size: cover !important;width: 24px;height: 42px;}
.e_comm_sec_new_inner_slide .owl-next span{display: none;}
.e_comm_sec_new_inner_slide .owl-prev span{display: none;}
.cam_img_te{width: 70px;transform: rotate(-25deg);}
.e_comm_sec_new_inner_slide .owl-dots {display: flex;align-items: center;position: absolute;bottom: -30px;
    gap: 6px;left: 0;right: 0; margin: 0 auto; text-align: center;width: 100%;justify-content: center;}
.e_comm_sec_new_inner_slide .owl-dot{height: 8px;width: 8px;border-radius: 50%;background: #A7A7A7 !important;}
.e_comm_sec_new_inner_slide .owl-dot.active{background: #fff !important;}































@media only screen and (min-width:1801px) and (max-width:3400px) {

.influ_banner_cont_sec_inner_cont{top: 500px;}
.level_up_sec_new_inner_head{width: 65%;}


}

@media only screen and (min-width:1801px) and (max-width:1900px) {

}

@media only screen and (min-width:1701px) and (max-width:1800px) {

}

@media only screen and (min-width:1601px) and (max-width:1700px) {

}

@media only screen and (min-width:1501px) and (max-width:1600px) {}

@media only screen and (min-width:1401px) and (max-width:1500px) {}

@media only screen and (min-width:1301px) and (max-width:1400px) {
    .hash_img{width: 300px;}
    .level_up_sec_new_inner_head h6{width: 45px;}
}



@media only screen and (min-width:1201px) and (max-width:1300px) {
.container-fluid {padding-right: 40px;padding-left: 40px;}

.Professional_photo_shoot_sec_inner_left h6 {font-size: 45px;margin: 0 0 10px 0;}
.upper_arrow {width: 240px;top: -70px;right: -30px;}
.prd_head h5{font-size: 36px;}
.prd_head h4{font-size: 36px;}
.Professional_photo_shoot_sec_inner_left p{font-size: 20px;}
.wave_slide1{width: 18%;}
.get_your_products_div {width:280px;height: 150px;}
.get_your_products span{font-size: 16px !important;}
.wave_slide2{bottom: 20px;}
.get_your_products{right: -50%;bottom: 20px;}
#photo_slide .owl-stage-outer{height: auto;}
.limited_time_sec {padding: 0px 0 20px 0;}
.limited_time_sec_left_single input{font-size: 15px;padding-left: 10px;}
.happy_clients_sec {padding: 20px 0 20px 0;}

.save_draft_sec_inner{padding: 30px 0;}
.save_draft_sec_inner .close {right: -20px;top: 25px;font-size: 20px;}
.save_draft_sec_inner img {width: 25%;margin-bottom: 25px;}
.save_draft_sec_inner h6 {font-size: 20px;padding-bottom: 15px;margin-bottom: 15px;}
.share_link_sec{margin-bottom: 10px;}
.or_sec_new{margin-bottom: 10px;}


.unevil_sec_new_des_inner_head{width: 80%;}
.pos_icn_center{width: 120px !important;}
.photoshoot_inceludes_single_left h5{font-size: 26px;}
.new_align_box{top: 150px;}
.photoshoot_inceludes_single_left_box ul li{font-size: 18px;}
.new_btn_place{margin-top: 200px;}
.product_shoot_sec_cate_inner_head h6{font-size: 34px;}
.product_single_cont h5{font-size: 17px;}
.budget_sec_new_sec_inner_body ul li{font-size: 20px;}
.limited_time_only_inner {grid-gap: 15px;}
.limited_time_only_right img{width: 400px;}
.limited_time_only_right{left: 115px;}
.lim_down{width: 170px !important;}
.lim_upper{width: 150px !important;}

.shoe_img{left: 62%;}



.tik_tok {bottom: -205px;right: 62px;}
.impact_sec_new_feed_inner_head h6{font-size: 40px;}
.folo_sec{right: -40px;}
.explore_sec_new_des_head h6{font-size: 38px;}
.make_impact_sec_new_head h6{font-size: 55px;}
.make_impact_sec_new_inner_right{top: -35px;}
.make_impact_single_top_left{right: 60%;}
.level_up_sec_new_inner_head h6{font-size: 50px;}
.influ_banner_cont_sec_inner_cont h1{font-size: 170px;}

.hash_img{width: 270px;}
.unlock_sec_new_inner_left p{font-size: 22px;}
.unlock_sec_new_inner_left a{font-size: 17px;}
.level_up_sec_new_inner_head p{font-size: 22px;}
.new_sing_hir .define_you_budget_body_sec_new_left_single p{font-size: 16px;}
.new_sing_hir .define_you_budget_body_sec_new_left_single h3{font-size: 12px;}
.define_you_budget_body_sec_new_left_single span{font-size: 40px;}

.stand_up_includes_sec_body_sec_right:after { width: 400px; height: 400px; right: -60px; top: -40px;}


}

@media only screen and (min-width:992px) and (max-width:1199px) {
.container-fluid {padding-right: 30px;padding-left: 30px;}

.hide_desk{display: flex;}
.hide_mob{display: none;}
.Professional_photo_shoot_sec_inner .row .col-md-6 {-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%;}
.Professional_photo_shoot_sec_inner_left h6 {font-size: 30px;margin: 0 0 10px 0;}
.prd_head h5 {font-size: 35px;width: 230px;height: 60px;}
.prd_head h4{font-size: 35px;}
.prd_head{margin: 0 0 15px 0;}
.Professional_photo_shoot_sec_inner_left p {font-size: 18px;margin: 0 0 15px 0;}
#photo_slide .owl-stage-outer{height: auto !important;}
.Professional_photo_shoot_sec_inner_right{margin-top: 25px;}
.Professional_photo_shoot_sec_inner_right_slide_single {width: 100%;margin: 0 0 auto auto;right: 0;
    margin-bottom: 30px;}
.Professional_photo_shoot_sec_inner_right_slide_single:after{left: 0px;}
.wave_slide2 { right: auto;left: 20px;}
.wave_slide1 {top: 5px; right: 0px;width: 110px;}
.upper_arrow {width: 190px;top: -60px;right: 40%;*transform: rotate(10deg);}
.get_your_products {right: 100px;bottom: 50px;}
.get_your_products img{width: 30px;}
.get_your_products_div {width: 250px;height: 100px;}
.get_your_products span {font-size: 16px;}
.Professional_photo_shoot_sec{padding-bottom: 0px;top: 15px;}
.limited_time_sec_main{grid-gap: 10px}
.limited_time_sec_right_head h4{font-size: 16px;}
.limited_time_sec_right_inner{padding: 15px;}
.limited_time_sec_right_inner ul li {margin-bottom: 15px;font-size: 14px;padding-left: 30px;}
.limited_time_sec_right_inner ul li:before {width: 15px;height: 15px;background-size: 15px;    background-repeat: no-repeat;top: 3px;}
.limited_time_sec_left_head h5{font-size: 16px;}
.limited_time_sec_left_head p{font-size: 14px;}
.limited_time_sec_left_head{margin-bottom: 10px;}
.limited_time_sec {padding: 30px 0 0px 0;}
.limited_time_sec_left_body_inner{grid-column-gap: 10px;}
.limited_time_sec_left_single label{font-size: 12px;}
.limited_time_sec_left_single input {height: 30px;font-size: 10px;padding-left: 12px;}
.limited_time_sec_left_single textarea {font-size: 10px;padding-left: 12px;}
.new_mobile_photo .iti--separate-dial-code .iti__selected-dial-code{display: none;}
.claim_offer_btn_sec button{font-size: 13px;}
.shoots_for_sec_new_head h6{font-size: 20px;}
.shoots_for_sec_new_head{margin-bottom: 10px;}
.shoots_for_sec_new_body{width: 100%;}
.category_sec_new_design_body{grid-gap: 20px;}
.category_sec_new_design_body_single_cont h5{font-size: 14px;line-height: inherit;}
.category_sec_new_design_body_single_cont{margin-top: 20px;}
.category_sec_new_design{padding-bottom: 0px;}
.happy_clients_sec_main_body_single_cont p{font-size: 12px;}
.happy_clients_sec_main_body_single_cont h6{font-size: 14px;}
.happy_clients_sec_main_body{grid-gap: 25px;}

.save_draft_sec_inner {padding: 15px;width: 100%;}
.save_draft_sec_inner .close{right: 10px;top: 10px;font-size: 16px;}
.save_draft_sec_inner img {width: 30%;margin-bottom: 20px;}
.save_draft_sec_inner h6 {font-size: 16px;padding-bottom: 15px;margin-bottom: 15px;}
.share_link_sec{margin-bottom: 10px;}
.sha_text {font-size: 14px;display: block !important;margin-bottom: 20px;margin-right: 0px !important;}
.share_link_sec li {margin-right: 15px;display: inline-block;}
.share_link_sec li a img{width: 100%;}
.or_sec_new {margin-bottom: 10px;font-size: 12px;}
.or_sec_new:before{width: 15%;top: 9px;left: 60px;}
.or_sec_new:after{width: 15%;top: 9px;right: 60px;}
.copy_link_sec_new span input {height: 45px;padding-left: 30px;font-size: 12px;}
.copy_link_sec_new span i {left: 10px; top: 4px;font-size: 14px;}
.copy_link_sec_new span a {top: -4px;font-size: 12px;padding: 8px 25px;}
.otp_popup_sec_inner_head h5{font-size: 14px;}
.copy_link_sec_new h5{font-size: 14px;}
.otp_popup_sec_inner .close{font-size: 16px;z-index: 111;}
.check_circle_sec {font-size: 10px;width: 100%;margin: 0 auto 10px;}
.check_circle_sec i{font-size: 15px;margin-right: 5px;}
.enter_otp_sec_new h6{font-size: 12px;margin-bottom: 10px;}
.enter_otp_sec_new_inner {grid-gap: 15px;width: 100%;}
.enter_otp_sec_new_inner_reset{width: 100%;}
.yes_agree_sec .container{font-size: 10px;}
.submit_btn_new_otp{margin: 15px 0 0 0;}
.submit_btn_new_otp button {font-size: 12px;padding: 6px 25px;}
.submit_btn_new_otp a {font-size: 12px;padding: 6px 25px;}
.enter_otp_sec_new_inner_reset a{font-size: 12px;}
.enter_otp_sec_new_inner_reset a.disa{font-size: 12px;}
.enter_otp_sec_new_inner_reset span{font-size: 11px;}
.otp_popup_sec_new .modal-dialog{max-width: 450px;}
.save_draft_sec_new .modal-dialog{max-width: 450px;}

.singers_sec .get_your_products span{font-size: 12px;}
.features_singers_sec_new{padding: 10px 0;background: none;}
.features_singers_sec_new_head{margin-bottom: 15px;}
.features_singers_sec_new_head h6{font-size: 18px;}
.features_singers_sec_inner {grid-template-columns: 1fr 1fr 1fr;grid-gap: 20px;}
.features_singers_sec_inner_single{padding: 10px;}
.features_singers_sec_inner_single_img_cont h5{font-size: 15px;}
.features_singers_sec_inner_single_img_cont ul li{font-size: 12px;}
.features_singers_sec_inner_single_img_cont h6{font-size: 12px;}
.singers_sec .Professional_photo_shoot_sec_inner_left h1{font-size: 15px;}
.comedy_sec .get_your_products span{font-size: 12px;}


.unevil_sec_new_des_inner_head{width: 90%;}
.unevil_sec_new_des_inner_head h6{font-size: 25px;}
.pos_icn_center{width: 70px !important;}
.get_start_btn {font-size: 16px;width: 180px;height: 35px;left: 60%;}
.photoshoot_inceludes:after{display: none;}
.photoshoot_inceludes:before{display: none;}
.photoshoot_inceludes_single{grid-template-columns: 1fr;}
.new_align_box{top: 245px;}
.new_btn_place{margin-top: 300px;}
.product_shoot_sec_cate_inner_body{grid-template-columns: 1fr 1fr;}
.product_shoot_sec_cate_inner_head h6{font-size: 25px;}
.budget_sec_new_sec_inner_body ul{display: block;padding: 20px;}
.budget_sec_new_sec_inner_body{height: 100%;}
.limited_time_only_inner{grid-template-columns: 1fr;}
.lim_upper {right: 40%;top: -40px;}


/*Hiring Influencers*/

.influ_banner_cont_sec_inner_cont{top: 250px;}
.text_box div{font-size: 40px;}
.text_box{top: 20px;}
.influ_banner_cont_sec_inner_cont h1{font-size: 70px;line-height: inherit;}
.influencers_video_sec_head h6{font-size: 50px;}
.influencers_video_sec_head p{font-size: 30px;}
.influencers_video_sec_head {width: 100%;margin-bottom: 100px;}
.define_you_budget_head h6{font-size: 40px;}
.define_you_budget_body_sec_new_left_single { height: 80px;padding: 0 40px;margin-bottom: 25px;}
.define_you_budget_body_sec_new_left_single span{font-size: 30px;}
.define_you_budget_body_sec_new_left_single p{font-size: 16px;}
.level_up_sec_new_inner_head{width: 100%;}
.level_up_sec_new_inner_head h6{font-size: 35px;}
.level_up_sec_new_inner_head p{font-size: 20px;}
.make_impact_sec_new{width: 100%;}
.make_impact_sec_new_head h6{font-size: 40px;}
.make_impact_sec_new_inner_right{top: -40px;}
.why_choose_sec_inf_inner_head h6{font-size: 40px;}
.why_choose_sec_inf:before{left: -50px;}
.why_choose_sec_inf_inner_body {grid-template-columns: 1fr;grid-gap: 80px;}
.why_choose_sec_inf:after{right: -50px;}
.explore_sec_new_des {padding: 51px 0 30px 0;}
.explore_sec_new_des_head h6{font-size: 30px;}
.explore_sec_new_des_inner_single_cont ul li{font-size: 12px;}
.explore_sec_new_des_inner_single_cont h5{font-size: 14px;}
.hire_btn_new_exp{margin: 30px auto 0;}
#exp_scrl .owl-nav {left: 0;width: 100%;}
.impact_sec_new_feed_inner_single_cont p{font-size: 16px;}
.camp_cont_sec h6{font-size: 15px;}
.camp_cont_sec h5{font-size: 10px;}
.camp_cont_sec h4{font-size: 12px;}
.camp_cont_sec p{font-size: 10px;}
.folo_sec { right: -15px;bottom: -15px;width: 40px;height: 40px;font-size: 6px;}
.our_camp_succes_inner_body{grid-gap: 30px;}
.limited_time_only_sec_inner{width: 100%;grid-gap: 40px;}
.png_1{width: 220px;}
.instag_img{left: 200px;}
.png_2 { width: 200px;object-fit: cover;left: 100px;top: 40%;}
.limited_time_only_sec {padding: 50px 0 20px 0;}
.limited_time_only_sec_inner_left:after{display: none;}
.you_img{left: 0px;bottom: 300px;}
.tik_tok {bottom: 100px;width: 74px;right: 0;}
.limited_time_only_sec_inner_right h6{font-size: 35px;}
.limited_time_only_sec_inner_right p{font-size: 16px;}

.influencer_sign_up_head_main_text h6{font-size: 32px;}
.influencer_sign_up_head_cont_sec h4 {font-size: 100px; line-height: 85px;}
.mobile_sign_sec_video_single:nth-child(1) video {width: 200px;z-index: 1}
.mobile_sign_sec_video_single:nth-child(2) video {width: 290px;height: 751px;}
.mobile_sign_sec_video_single:nth-child(3) video {width: 250px;height: 668px;}
.mobile_sign_sec_video{margin: 15px 0 0 0;}
.yt_sec {top: 60px;left: -20px;width: 60px;}
.insta_sec {width: 60px; z-index: 1; top: 60px;right: -20px;}
.hash_img {right: 0; bottom: 0; width: 70px;}
.unlock_sec_new_inner_left h6{font-size: 30px;}
.unlock_sec_new_inner_left p{font-size: 20px;}
.jf_img {right: 191px; width: 190px;}
.j_adv_img { right: 15px; top: -25px; width: 50px;}
.start_journey_infl:after{width: 50px;height: 65px;}
.influencer_flid_sing_1{width: 200px;}
.influencer_flid_sing_1 h5{font-size: 15px;}
.join_newtwork_infl_inner_body_left{padding: 15px;}
.join_newtwork_infl_inner_body{align-items: center;}
.join_newtwork_infl_inner_body_right{width: 100%;}
.view_cate_btn_new{font-size: 13px;}
.influencer_flid_sing_2{width: 150px;}
.influencer_flid_sing_2 h5{font-size: 15px;}

.influencer_flid_sing_3{width: 150px;}
.influencer_flid_sing_3 h5{font-size: 15px;}

.influencer_flid_sing_4{width:160px;}
.influencer_flid_sing_4 h5{font-size: 15px;}

.influencer_flid_sing_5{width: 180px;}
.influencer_flid_sing_5 h5{font-size: 15px;}

.influencer_flid_sing_6{width: 120px;right: 0px;}
.influencer_flid_sing_6 h5{font-size: 15px;}

.influencer_flid_sing_7{width: 130px;}
.influencer_flid_sing_7 h5{font-size: 15px;}

.stand_up_come_header_right_icn{gap:30px;}
.stand_up_come_header{height: 450px;}
.stand_up_come_left_cont_sec {flex: 0 0 400px;width: 400px;}
.stand_up_come_left_cont_sec h6 {font-size: 35px;margin-bottom: 10px;}
.stand_up_come_left_cont_sec span {font-size: 30px;padding: 8px 29px;margin-bottom: 15px;}
.stand_up_come_left_cont_sec p {font-size: 15px;line-height: inherit;margin: 10px 0;}
.stand_up_come_left_cont_sec a {font-size: 20px;}
.stand_up_come_right_cont_sec{right: -25px;bottom: -70px;}
.stand_up_includes_sec:after{display: none;}
.stand_up_includes_sec:before{display: none;}
.stand_up_includes_sec_body_sec {grid-template-columns: 1fr 1fr;grid-gap: 30px;align-items: center;margin-top: 25px;}
.standup-comedy-service-includes-left-book-now-folder {gap: 20px;}
.standup-comedy-service-includes-iocn img{width: 45px;}
.standup-comedy-service-includes-left-book-now-folder h2 {font-size: 13px;line-height: inherit;width: 350px;}
.smile_img{display: none;}
.smail_img{display: none;}
.stand_up_includes_sec_body_sec_right:after {width: 300px;height: 300px;z-index: -1;right: 0;top: 0;}
.stand_fea_sec_new_single_cont h6{font-size: 16px;}
.stand_fea_sec_new_single_cont p{font-size: 12px;}
.play_cin_blue_sec{height: 40px;width: 40px;}
.play_cin_blue_sec span i{font-size: 12px;}
.play_cin_blue_sec p{font-size: 12px;}
.stand_fea_sec_new_single:hover .play_cin_blue_sec {width: 85px;height: 35px;}
.book_now_sec_stand_inner {grid-template-columns: 1fr 1fr;grid-gap: 20px;align-items: center;}
.book_now_sec_stand_inner_left{position: sticky;top: 0;height: fit-content;}



}

@media only screen and (min-width:768px) and (max-width:991px) {
.container-fluid {padding-right: 30px;padding-left: 30px;}
.hide_desk{display: flex;}
.hide_mob{display: none;}
.Professional_photo_shoot_sec_inner .row .col-md-6 {-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%;}
.Professional_photo_shoot_sec_inner_left h6 {font-size: 30px;margin: 0 0 10px 0;}
.prd_head h5 {font-size: 35px;width: 230px;height: 60px;}
.prd_head h4{font-size: 35px;}
.prd_head{margin: 0 0 15px 0;}
.Professional_photo_shoot_sec_inner_left p {font-size: 18px;margin: 0 0 15px 0;}
#photo_slide .owl-stage-outer{height: auto !important;}
.Professional_photo_shoot_sec_inner_right{margin-top: 25px;}
.Professional_photo_shoot_sec_inner_right_slide_single {width: 100%;margin: 0 0 auto auto;right: 0;
    margin-bottom: 30px;}
.Professional_photo_shoot_sec_inner_right_slide_single:after{left: 0px;}
.wave_slide2 { right: auto;left: 20px;}
.wave_slide1 {top: 5px; right: 0px;width: 110px;}
.upper_arrow {width: 190px;top: -60px;right: 40%;*transform: rotate(10deg);}
.get_your_products {right: 100px;bottom: 50px;}
.get_your_products img{width: 30px;}
.get_your_products_div {width: 250px;height: 100px;}
.get_your_products span {font-size: 16px;}
.Professional_photo_shoot_sec{padding-bottom: 0px;top: 15px;}
.limited_time_sec_main{grid-gap: 10px}
.limited_time_sec_right_head h4{font-size: 16px;}
.limited_time_sec_right_inner{padding: 15px;}
.limited_time_sec_right_inner ul li {margin-bottom: 15px;font-size: 14px;padding-left: 30px;}
.limited_time_sec_right_inner ul li:before {width: 15px;height: 15px;background-size: 15px;    background-repeat: no-repeat;top: 3px;}
.limited_time_sec_left_head h5{font-size: 16px;}
.limited_time_sec_left_head p{font-size: 14px;}
.limited_time_sec_left_head{margin-bottom: 10px;}
.limited_time_sec {padding: 30px 0 0px 0;}
.limited_time_sec_left_body_inner{grid-column-gap: 10px;}
.limited_time_sec_left_single label{font-size: 12px;}
.limited_time_sec_left_single input {height: 30px;font-size: 10px;padding-left: 12px;}
.limited_time_sec_left_single textarea {font-size: 10px;padding-left: 12px;}
.new_mobile_photo .iti--separate-dial-code .iti__selected-dial-code{display: none;}
.claim_offer_btn_sec button{font-size: 13px;}
.shoots_for_sec_new_head h6{font-size: 20px;}
.shoots_for_sec_new_head{margin-bottom: 10px;}
.shoots_for_sec_new_body{width: 100%;}
.category_sec_new_design_body{grid-gap: 20px;}
.category_sec_new_design_body_single_cont h5{font-size: 14px;line-height: inherit;}
.category_sec_new_design_body_single_cont{margin-top: 20px;}
.category_sec_new_design{padding-bottom: 0px;}
.happy_clients_sec_main_body_single_cont p{font-size: 12px;}
.happy_clients_sec_main_body_single_cont h6{font-size: 14px;}
.happy_clients_sec_main_body{grid-gap: 25px;}


.save_draft_sec_inner {padding: 15px;width: 100%;}
.save_draft_sec_inner .close{right: 10px;top: 10px;font-size: 16px;}
.save_draft_sec_inner img {width: 30%;margin-bottom: 20px;}
.save_draft_sec_inner h6 {font-size: 16px;padding-bottom: 15px;margin-bottom: 15px;}
.share_link_sec{margin-bottom: 10px;}
.sha_text {font-size: 14px;display: block !important;margin-bottom: 20px;margin-right: 0px !important;}
.share_link_sec li {margin-right: 15px;display: inline-block;}
.share_link_sec li a img{width: 100%;}
.or_sec_new {margin-bottom: 10px;font-size: 12px;}
.or_sec_new:before{width: 15%;top: 9px;left: 60px;}
.or_sec_new:after{width: 15%;top: 9px;right: 60px;}
.copy_link_sec_new span input {height: 45px;padding-left: 30px;font-size: 12px;}
.copy_link_sec_new span i {left: 10px; top: 4px;font-size: 14px;}
.copy_link_sec_new span a {top: -4px;font-size: 12px;padding: 8px 25px;}
.otp_popup_sec_inner_head h5{font-size: 14px;}
.copy_link_sec_new h5{font-size: 14px;}
.otp_popup_sec_inner .close{font-size: 16px;z-index: 111;}
.check_circle_sec {font-size: 10px;width: 100%;margin: 0 auto 10px;}
.check_circle_sec i{font-size: 15px;margin-right: 5px;}
.enter_otp_sec_new h6{font-size: 12px;margin-bottom: 10px;}
.enter_otp_sec_new_inner {grid-gap: 15px;width: 100%;}
.enter_otp_sec_new_inner_reset{width: 100%;}
.yes_agree_sec .container{font-size: 10px;}
.submit_btn_new_otp{margin: 15px 0 0 0;}
.submit_btn_new_otp button {font-size: 12px;padding: 6px 25px;}
.submit_btn_new_otp a {font-size: 12px;padding: 6px 25px;}
.enter_otp_sec_new_inner_reset a{font-size: 12px;}
.enter_otp_sec_new_inner_reset a.disa{font-size: 12px;}
.enter_otp_sec_new_inner_reset span{font-size: 11px;}
.otp_popup_sec_new .modal-dialog{max-width: 450px;}
.save_draft_sec_new .modal-dialog{max-width: 450px;}


.singers_sec .get_your_products span{font-size: 12px;}
.features_singers_sec_new{padding: 10px 0;background: none;}
.features_singers_sec_new_head{margin-bottom: 15px;}
.features_singers_sec_new_head h6{font-size: 18px;}
.features_singers_sec_inner {grid-template-columns: 1fr 1fr 1fr;grid-gap: 20px;}
.features_singers_sec_inner_single{padding: 10px;}
.features_singers_sec_inner_single_img_cont h5{font-size: 15px;}
.features_singers_sec_inner_single_img_cont ul li{font-size: 12px;}
.features_singers_sec_inner_single_img_cont h6{font-size: 12px;}
.singers_sec .Professional_photo_shoot_sec_inner_left h1{font-size: 15px;}
.comedy_sec .get_your_products span{font-size: 12px;}



.unevil_sec_new_des_inner_head{width: 90%;}
.unevil_sec_new_des_inner_head h6{font-size: 25px;}
.pos_icn_center{width: 70px !important;}
.get_start_btn {font-size: 16px;width: 180px;height: 35px;left: 60%;}
.photoshoot_inceludes:after{display: none;}
.photoshoot_inceludes:before{display: none;}
.photoshoot_inceludes_single{grid-template-columns: 1fr;}
.new_align_box{top: 245px;}
.new_btn_place{margin-top: 300px;}
.product_shoot_sec_cate_inner_body{grid-template-columns: 1fr 1fr;}
.product_shoot_sec_cate_inner_head h6{font-size: 25px;}
.budget_sec_new_sec_inner_body ul{display: block;padding: 20px;}
.budget_sec_new_sec_inner_body{height: 100%;}
.limited_time_only_inner{grid-template-columns: 1fr;}
.lim_upper {right: 20%;top: -40px;}


/*Hiring Influencers*/

.influ_banner_cont_sec_inner_cont{top: 250px;}
.text_box div{font-size: 40px;}
.text_box{top: 20px;}
.influ_banner_cont_sec_inner_cont h1{font-size: 70px;line-height: inherit;}
.influencers_video_sec_head h6{font-size: 50px;}
.influencers_video_sec_head p{font-size: 30px;}
.influencers_video_sec_head {width: 100%;margin-bottom: 100px;}
.define_you_budget_head h6{font-size: 40px;}
.define_you_budget_body_sec_new_left_single { height: 80px;padding: 0 40px;margin-bottom: 25px;}
.define_you_budget_body_sec_new_left_single span{font-size: 30px;}
.define_you_budget_body_sec_new_left_single p{font-size: 16px;}
.level_up_sec_new_inner_head{width: 100%;}
.level_up_sec_new_inner_head h6{font-size: 35px;}
.level_up_sec_new_inner_head p{font-size: 20px;}
.make_impact_sec_new{width: 100%;}
.make_impact_sec_new_head h6{font-size: 40px;}
.make_impact_sec_new_inner_right{top: -40px;}
.why_choose_sec_inf_inner_head h6{font-size: 40px;}
.why_choose_sec_inf:before{left: -50px;}
.why_choose_sec_inf_inner_body {grid-template-columns: 1fr;grid-gap: 80px;}
.why_choose_sec_inf:after{right: -50px;}
.explore_sec_new_des {padding: 51px 0 30px 0;}
.explore_sec_new_des_head h6{font-size: 30px;}
.explore_sec_new_des_inner_single_cont ul li{font-size: 12px;}
.explore_sec_new_des_inner_single_cont h5{font-size: 14px;}
.hire_btn_new_exp{margin: 30px auto 0;}
#exp_scrl .owl-nav {left: 0;width: 100%;}
.impact_sec_new_feed_inner_single_cont p{font-size: 16px;}
.camp_cont_sec h6{font-size: 15px;}
.camp_cont_sec h5{font-size: 10px;}
.camp_cont_sec h4{font-size: 12px;}
.camp_cont_sec p{font-size: 10px;}
.folo_sec { right: -15px;bottom: -15px;width: 40px;height: 40px;font-size: 6px;}
.our_camp_succes_inner_body{grid-gap: 30px;}
.limited_time_only_sec_inner{width: 100%;grid-gap: 40px;}
.png_1{width: 220px;}
.instag_img{left: 200px;}
.png_2 { width: 200px;object-fit: cover;left: 100px;top: 40%;}
.limited_time_only_sec {padding: 50px 0 20px 0;}
.limited_time_only_sec_inner_left:after{display: none;}
.you_img{left: 0px;bottom: 300px;}
.tik_tok {bottom: 100px;width: 74px;right: 0;}
.limited_time_only_sec_inner_right h6{font-size: 35px;}
.limited_time_only_sec_inner_right p{font-size: 16px;}



.influencer_sign_up_head_main_text h6{font-size: 32px;}
.influencer_sign_up_head_cont_sec h4 {font-size: 100px; line-height: 85px;}
.mobile_sign_sec_video_single:nth-child(1) video {width: 200px;z-index: 1}
.mobile_sign_sec_video_single:nth-child(2) video {width: 290px;height: 751px;}
.mobile_sign_sec_video_single:nth-child(3) video {width: 250px;height: 668px;}
.mobile_sign_sec_video{margin: 15px 0 0 0;}
.yt_sec {top: 60px;left: -20px;width: 60px;}
.insta_sec {width: 60px; z-index: 1; top: 60px;right: -20px;}
.hash_img {right: 0; bottom: 0; width: 70px;}
.unlock_sec_new_inner_left h6{font-size: 30px;}
.unlock_sec_new_inner_left p{font-size: 20px;}
.jf_img {right: 191px; width: 190px;}
.j_adv_img { right: 15px; top: -25px; width: 50px;}
.start_journey_infl:after{width: 50px;height: 65px;}
.influencer_flid_sing_1{width: 200px;}
.influencer_flid_sing_1 h5{font-size: 15px;}
.join_newtwork_infl_inner_body_left{padding: 15px;}
.join_newtwork_infl_inner_body{align-items: center;}
.join_newtwork_infl_inner_body_right{width: 100%;}
.view_cate_btn_new{font-size: 13px;}
.influencer_flid_sing_2{width: 150px;}
.influencer_flid_sing_2 h5{font-size: 15px;}

.influencer_flid_sing_3{width: 150px;}
.influencer_flid_sing_3 h5{font-size: 15px;}

.influencer_flid_sing_4{width:160px;}
.influencer_flid_sing_4 h5{font-size: 15px;}

.influencer_flid_sing_5{width: 180px;}
.influencer_flid_sing_5 h5{font-size: 15px;}

.influencer_flid_sing_6{width: 120px;right: 0px;}
.influencer_flid_sing_6 h5{font-size: 15px;}

.influencer_flid_sing_7{width: 130px;}
.influencer_flid_sing_7 h5{font-size: 15px;}


.stand_up_come_header_right_icn{gap:30px;}
.stand_up_come_header{height: 450px;}
.stand_up_come_left_cont_sec {flex: 0 0 400px;width: 400px;}
.stand_up_come_left_cont_sec h6 {font-size: 35px;margin-bottom: 10px;}
.stand_up_come_left_cont_sec span {font-size: 30px;padding: 8px 29px;margin-bottom: 15px;}
.stand_up_come_left_cont_sec p {font-size: 15px;line-height: inherit;margin: 10px 0;}
.stand_up_come_left_cont_sec a {font-size: 20px;}
.stand_up_come_right_cont_sec{right: -25px;bottom: -70px;}
.stand_up_includes_sec:after{display: none;}
.stand_up_includes_sec:before{display: none;}
.stand_up_includes_sec_body_sec {grid-template-columns: 1fr 1fr;grid-gap: 30px;align-items: center;margin-top: 25px;}
.standup-comedy-service-includes-left-book-now-folder {gap: 20px;}
.standup-comedy-service-includes-iocn img{width: 45px;}
.standup-comedy-service-includes-left-book-now-folder h2 {font-size: 13px;line-height: inherit;width: 350px;}
.smile_img{display: none;}
.smail_img{display: none;}
.stand_up_includes_sec_body_sec_right:after {width: 300px;height: 300px;z-index: -1;right: 0;top: 0;}
.stand_fea_sec_new_single_cont h6{font-size: 16px;}
.stand_fea_sec_new_single_cont p{font-size: 12px;}
.play_cin_blue_sec{height: 40px;width: 40px;}
.play_cin_blue_sec span i{font-size: 12px;}
.play_cin_blue_sec p{font-size: 12px;}
.stand_fea_sec_new_single:hover .play_cin_blue_sec {width: 85px;height: 35px;}
.book_now_sec_stand_inner {grid-template-columns: 1fr 1fr;grid-gap: 20px;align-items: center;}
.book_now_sec_stand_inner_left{position: sticky;top: 0;height: fit-content;}






}

@media only screen and (max-width:767px) {

.container-fluid {padding-right: 15px;padding-left: 15px;}
.hide_desk{display: flex;}
.hide_mob{display: none;}

.Professional_photo_shoot_sec_inner_left h6 {font-size: 16px;margin: 0 0 5px 0;}
.prd_head h4{font-size: 16px;}
.prd_head h5{font-size: 18px;width: 110px;height: 30px;}
.prd_head {column-gap: 10px;margin: 0 0 10px 0;}
.Professional_photo_shoot_sec_inner_left p {font-size: 12px;margin: 0 0 15px 0;width: 100%;}
.share_page_btn{font-size: 12px;margin-bottom: 30px;}
.get_your_products span {font-size: 10px;line-height: inherit;}
.get_your_products_div {width: 140px;height: 50px;border-radius: 12px;}
.get_your_products img{width: 30px;}
.get_your_products{right: 0px;bottom: 25px;*transform: rotate(25deg);}
.Professional_photo_shoot_sec_inner_right_slide_single{width: 100%;right: 0px;}

.Professional_photo_shoot_sec{display: block;}

.limited_time_sec{padding: 0 0 15px 0;}
.limited_time_sec_main { display: flex; flex-wrap: wrap;flex-direction: column-reverse;gap: 20px;}
.limited_time_sec_left_head h5{font-size: 14px;margin: 0px;}
.limited_time_sec_left_head p{font-size: 12px;}
.limited_time_sec_left_head{margin-bottom: 10px;}
.limited_time_sec_left_single label{font-size: 12px;}
.limited_time_sec_left_single input {width: 100%;height: 32px; font-size: 12px;padding-left: 10px;}
.limited_time_sec_left_single{margin-bottom: 10px;}
.limited_time_sec_left_single textarea {width: 100%;font-size: 11px;padding-left: 10px;}
.new_mobile_photo .iti--separate-dial-code .iti__selected-dial-code{font-size: 8px;display: none;}
.new_mobile_photo .iti--separate-dial-code .iti__flag-container{top: 0 !important;}
.limited_time_sec_left_body_inner {grid-template-columns: 1fr;grid-column-gap: 15px;}
.new_mobile_photo.limited_time_sec_left_single input {padding-left: 65px !important;}
.claim_offer_btn_sec button {font-size: 12px;padding: 6px 25px;}
.limited_time_sec_right_head h4{font-size: 12px;}
.limited_time_sec_right_inner{padding: 15px;border-radius: 10px;}
.limited_time_sec_right_inner ul li{font-size: 12px;}
.limited_time_sec_right_inner ul li:before {width: 15px;height: 15px;background-size: 15px;    background-repeat: no-repeat;top: 3px;}
.limited_time_sec_right_inner ul li {margin-bottom: 12px;font-size: 12px;padding-left: 25px;}
.shoots_for_sec_new {padding: 0 0 15px 0;}
.shoots_for_sec_new_head h6{font-size: 16px;margin-bottom: 12px;}
.shoots_for_sec_new_head{margin-bottom: 15px;}
.Professional_photo_shoot_sec {padding: 15px 0;}
.Professional_photo_shoot_sec_inner_right_slide_single:after {left: 0;top: 20px;}
.upper_arrow { width: 110px;top: -25px;right: 35%;transform: rotate(15deg);}
#photo_slide .owl-stage-outer {height: auto !important;}
.wave_slide2 {bottom: 55px;width: 40px;right: auto;left: 15px;}
.shoots_for_sec_new_body{width: 100%;text-align: center;gap: 20px;display: flex;flex-wrap: wrap;   justify-content: center;}
.shoots_for_sec_new_single {display: inline-block;width: 25%;}
.category_sec_new_design {padding: 10px 0 15px 0;}
.category_sec_new_design_body {grid-template-columns: 1fr 1fr;grid-gap: 15px;}
.category_sec_new_design_body_single_img:after{left: 0px;}
.category_sec_new_design_body_single_cont{margin-top: 15px;}
.category_sec_new_design_body_single_cont h5{font-size: 12px;line-height: inherit;}
.happy_clients_sec {padding: 5px 0;}
.happy_clients_sec_main_body_single_img:after{left: 0px;}
.happy_clients_sec_main_body_single_cont {margin: 20px 0 0 0;}
.happy_clients_sec_main_body_single_cont h6{font-size: 14px;}
.happy_clients_sec_main_body_single_cont p{font-size: 12px;}
.happy_clients_sec_main_body_single_img img{*height: 380px;}
.wave_cate_right1{display: none !important;}
.wave_cate_left1{display: none !important;}

.wave_cate_right {right: -40px;top: -45px;width: 90px;}
.wave_cate_left {left: -15px; top: 110px;width: 100px;}
.wave_slide1{width: 70px;right: 0px;}
.shoots_for_sec_new_single:last-child{width: 18%;}

.happy_clients_sec_main_body {grid-template-columns: 1fr;grid-gap: 25px;}
.limited_time_sec_left {flex: 0 0 100%;width: 100%;}
.limited_time_sec_right{flex: 0 0 100%;width: 100%;}

.save_draft_sec_inner {padding: 15px;width: 100%;}
.save_draft_sec_inner .close{right: 10px;top: 10px;font-size: 16px;}
.save_draft_sec_inner img {width: 30%;margin-bottom: 20px;}
.save_draft_sec_inner h6 {font-size: 16px;padding-bottom: 15px;margin-bottom: 15px;}
.share_link_sec{margin-bottom: 10px;}
.sha_text {font-size: 14px;display: block !important;margin-bottom: 20px;margin-right: 0px !important;}
.share_link_sec li {margin-right: 15px;display: inline-block;}
.share_link_sec li a img{width: 100%;}
.or_sec_new {margin-bottom: 10px;font-size: 12px;}
.or_sec_new:before{width: 15%;top: 9px;left: 60px;}
.or_sec_new:after{width: 15%;top: 9px;right: 60px;}
.copy_link_sec_new span input {height: 45px;padding-left: 30px;font-size: 12px;}
.copy_link_sec_new span i {left: 10px; top: 4px;font-size: 14px;}
.copy_link_sec_new span a {top: -4px;font-size: 12px;padding: 8px 25px;}
.otp_popup_sec_inner_head h5{font-size: 14px;}
.copy_link_sec_new h5{font-size: 14px;}
.otp_popup_sec_inner .close{font-size: 16px;z-index: 111;}
.check_circle_sec {font-size: 10px;width: 100%;margin: 0 auto 10px;}
.check_circle_sec i{font-size: 15px;margin-right: 5px;}
.enter_otp_sec_new h6{font-size: 12px;margin-bottom: 10px;}
.enter_otp_sec_new_inner {grid-gap: 15px;width: 100%;}
.enter_otp_sec_new_inner_reset{width: 100%;}
.yes_agree_sec .container{font-size: 10px;}
.submit_btn_new_otp{margin: 15px 0 0 0;}
.submit_btn_new_otp button {font-size: 12px;padding: 6px 25px;}
.submit_btn_new_otp a {font-size: 12px;padding: 6px 25px;}
.enter_otp_sec_new_inner_reset a{font-size: 12px;}
.enter_otp_sec_new_inner_reset a.disa{font-size: 12px;}
.enter_otp_sec_new_inner_reset span{font-size: 11px;}
.singers_sec .Professional_photo_shoot_sec_inner_left p{width: 100%;margin-bottom: 10px;}
.singers_sec .Professional_photo_shoot_sec_inner_left h1 {font-size: 12px;margin: 0 0 15px 0;
    width: 100%;}
.singers_sec .get_your_products span{font-size: 8px;}
.features_singers_sec_new{padding: 10px 0;background: none;}
.features_singers_sec_new_head{margin-bottom: 15px;}
.features_singers_sec_new_head h6{font-size: 15px;}
.features_singers_sec_inner {grid-template-columns: 1fr;grid-gap: 20px;}
.features_singers_sec_inner_single{padding: 10px;}
.features_singers_sec_inner_single_img_cont h5{font-size: 15px;}
.features_singers_sec_inner_single_img_cont ul li{font-size: 12px;}
.features_singers_sec_inner_single_img_cont h6{font-size: 12px;}
.comedy_sec .get_your_products span{font-size: 8px;}

.inquiry_sec_new_inner img{width: 100px;margin: 0 auto 20px;}
.inquiry_sec_new_inner p{position: relative;margin-bottom: 0px;font-weight: 500;font-size: 14px;color: #000000;}


.send_sec_btn {font-size: 12px;padding: 5px 10px;}    
.back_btn_new{font-size: 12px;padding: 5px 10px;}
.step_round{grid-gap: 0px;}
.steping_prog_bar_sec{left: 15%;}
.step_round span{height: 4px;width: 4px;}
.steping_prog_bar_sec h6{font-size: 12px;}

.upgrade_prem_left_head h5{font-size: 16px;}
.upgrade_prem_left_head h5 img{width: 60%;}
.upgrade_prem_left h6{font-size: 12px;height: 25px;}
.upgrade_prem_middle h6{font-size: 12px;height: 25px;}
.upgrade_prem_right h6{font-size: 12px;height: 25px;}
.upgrade_prem_right h6 span{font-size: 9px;}
.jobs_list li{font-size: 10px;padding-bottom:0px;min-height: 40px;}

/*Product Photoshoot landibg page*/

.desk_ver{display: none;}    
.mob_ver{display: block;} 

.logo_land_sec_new {padding: 10px 0 5px 0;}
.logo_land_sec_new_inner img {width: 110px;}
.unevil_sec_new_des_inner_head {width: 100%;margin: 0 auto 20px;}
.unevil_sec_new_des_inner_head h6{font-size: 14px;line-height: inherit;}

.unevil_sec_mobile_view{position: relative;    margin-top: 35px;}
.unevil_sec_mobile_view_inner{position: relative;}
.unevil_sec_mobile_view_inner_single{position: relative;}
.unevil_sec_mobile_view_inner_single img{width: 100%;object-fit: cover;*border-radius: 20px;}


.photoshoot_inceludes{padding: 15px 0;}
.photoshoot_inceludes_inner:before{display: none;}
.photoshoot_inceludes:after{display: none;}
.photoshoot_inceludes_single {grid-template-columns: 1fr;grid-gap: 15px;}
.photoshoot_inceludes_single_left h5 {gap: 10px;font-size: 14px;margin-bottom: 10px;}
.photoshoot_inceludes_single_left h5 img{width: 25px;}
.photoshoot_inceludes_single_left_box{border-radius: 10px;padding: 12px;box-shadow: none;}
.photoshoot_inceludes_single_left_box ul li {font-size: 12px; padding-left: 20px;}
.photoshoot_inceludes_single_left_box ul li::before{font-size: 12px;}
.photoshoot_inceludes:before{display: none;}
.photoshoot_inceludes:after{display: none;}
.photoshoot_inceludes_single_right img{width: 180px;}
.vanity_img{bottom: -10px;width: 120px !important;position: relative;}
.grp_img {left: 130px;top: 65px;width: 130px !important;}
.new_align_box {position: relative;align-items: flex-end;top: 70px;flex-direction: column-reverse;display: flex;}
.cup_tea {position: absolute; right: 0; top: 0;width: 110px !important;}
.unevil_sec_new_des:before{display: none;}
.unevil_sec_new_des:after{display: none;}
.fir_img_new {position: absolute; width: 60px; right: 5px; bottom: -30px;z-index: 111;}
.review_sec_new_list{margin-top: 120px;}
.get_start_btn {font-size: 12px; width: 120px;height: 30px;bottom: 70px;}
.review_sec_new_list_single p {position: relative;color: #000000;font-size: 10px; font-weight: 500;width: 100%;
    left: 0;text-align: center;}
.review_sec_new_list_single p:before{background-size: 15px;left: 0;}
.review_sec_new_list_single p:after{background-size: 15px;right: 0;}
.unevil_sec_new_des{padding: 10px 0 0 0;}
.photoshoot_inceludes_single_left_box.right_box:after{display: none;}
.photoshoot_inceludes_inner:after{display: none;}
.shoe_img {width: 100px !important; bottom: -70px; right: 0; left: 20px;}
.new_bag {width: 130px !important; right: 115px;position: relative;top: 30px;}
.budget_sec_new_btn a { font-size: 10px;font-weight: 500;padding: 5px 20px;}
.product_shoot_sec_cate{padding: 10px 0;}
.product_shoot_sec_cate_inner_head{margin-bottom: 15px;}
.product_shoot_sec_cate_inner_head h6{font-size: 16px;}
.product_shoot_sec_cate_inner_body{grid-template-columns: 1fr;}
.product_single_cont{width: auto;}
.product_single_cont h5{font-size: 14px;}
.budget_sec_new_sec {padding: 15px 0 10px 0;}
.product_shoot_sec_cate_inner_head h6{font-size: 14px;line-height: inherit;}
.budget_sec_new_sec .product_shoot_sec_cate_inner_head {margin-bottom: 15px;}
.budget_sec_new_sec_inner_body {height: 100%;border-radius: 12px;}
.budget_sec_new_sec_inner_body ul{display: block;padding: 12px;}
.budget_sec_new_sec_inner_body ul li{font-size: 12px;margin-bottom: 12px;padding-left: 15px;}
.budget_sec_new_sec_inner_body ul li:before {top: 4px;height: 8px;width: 8px;}
.budget_sec_new_sec_inner_body ul li:last-child{margin-bottom: 0px;}
.shhots_for_sec_new{padding: 10px 0 30px 0;}
.shhots_for_sec_new .product_shoot_sec_cate_inner_head {margin-bottom: 15px;}
.shhots_for_sec_new_inner_body{display: block;margin-bottom: 15px;text-align: center;}
.shhots_for_sec_single {display: inline-block;width: 20%;margin: 10px;}
.shhots_for_sec_single img{width: 100%;}
.new_happy .shoots_for_sec_new_head h6{font-size: 20px;}
.limited_time_only:after{display: none;}
.new_happy .budget_sec_new_btn {margin-top: 20px;}
.limited_time_only_inner { grid-template-columns: 1fr;grid-gap: 15px;}
.limited_time_only_left_head h6 {font-size: 24px; margin-bottom: 10px;}
.limited_time_only_left_head p{font-size: 14px;}
.limited_time_only_left_head{margin-bottom: 20px;}
.limited_time_only_left_grid {grid-template-columns: 1fr;grid-column-gap: 15px;}
.limited_time_only_left_single{margin-bottom: 15px;}
.limited_time_only_left_single input{font-size: 12px;}
.limited_time_only_left_single textarea{font-size: 12px;}
.submit_btn_limited a {font-size: 12px;padding: 5px 25px;}
.submit_btn_limited button {font-size: 12px;padding: 5px 25px;}
.limited_time_only_right{left: 0px;top: 80px;margin-bottom: 140px;}
.lim_upper {position: absolute;right: 0;top: -65px;width: 100px !important;}
.lim_down {left: 0;bottom: -50px;width: 100px !important;}




/*Influencer's page*/

.video_sec_image{position: relative;display: block;}
.video_sec_image img{width: 100%;}
.video_sec_vid{display: none;}
.influ_banner_cont_sec_inner_logo{top: 10px;width: 150px;}
.influ_banner_cont_sec_inner_cont{top: 380px;}
.text_box div{font-size: 25px;}
.influ_banner_cont_sec_inner_cont h1{font-size: 40px;line-height: inherit;}
.text_box{top: 30px;}
.get_start_btn_more {width: 130px;height: 35px;font-size: 14px;top: 70px;left: 0;margin: 0 auto;}
.influencers_video_sec_inner{text-align: center;}
.influencers_video_sec_head{width: 100%;}
.influencers_video_sec_head h6{font-size: 30px;margin: 0 0 10px 0;}
.influencers_video_sec_head p{font-size: 13px;}
.hire_btn_in { font-size: 12px;padding: 5px 25px;}
.influencers_video_sec_body{width: 100%;}
.influencers_video_sec_single:nth-child(2){top: -20px;}
.influencers_video_sec_single:nth-child(1){left: 5px;}
.influencers_video_sec_single:nth-child(3){right: 8px;}
.define_you_budget{padding: 10px 0;}
.define_you_budget_head h6{font-size: 22px;}
.define_you_budget_body_sec_new {grid-template-columns: 1fr;grid-gap: 20px;width: 100%;}
.define_you_budget_body_sec_new_left_single{padding: 0 15px;margin-bottom: 20px;height: 60px;}
.define_you_budget_body_sec_new_left_single p{font-size: 12px;}
.define_you_budget_head{margin-bottom: 20px;}
.define_you_budget_body_sec_new_left_single span{font-size: 15px;left: -5px;top: -5px;}
.define_you_budget_body_sec_new_left_single:nth-child(2) span{font-size: 15px;right: -5px;top: -5px;left: auto;}
.hire_btn_new{text-align: center;}
.level_up_sec_new{padding: 15px 0;}
.level_up_sec_new_inner_head{width: 100%;}
.level_up_sec_new_inner_head h6{font-size: 16px;margin-bottom: 5px;}
.level_up_sec_new_inner_head p{width: 100%;font-size: 12px;margin: 0 auto 10px;}
.level_explore{ font-size: 12px;padding: 5px 25px;}
.level_up_sec_new_inner_body:before {width: 30px;height: 14px;top: -60px;}
.level_up_sec_new_inner_body:after {width: 30px;height: 14px;bottom: 0;}
.level_up_sec_new_inner_single:nth-child(1){top: -50px;}
.level_up_sec_new_inner_single:nth-child(2){top: -30px;}
.level_up_sec_new_inner_single:nth-child(5){top: -30px;}
.level_up_sec_new_inner_single:nth-child(6){top: -50px;}
.level_up_sec_new_inner_body{gap:5px;margin-top: 60px;}
.make_impact_sec_new{width: 100%;text-align: center;padding: 20px 0 20px 0;}
.make_impact_sec_new_head h6{font-size: 18px;}
.make_impact_sec_new_inner_body{display: flex;flex-direction: column-reverse;    align-items: flex-start;}
.make_impact_single_top img{width: 100px;}
.make_impact_single_top_right img{width: 90px;}
.make_impact_single_top_left img{width: 80px;}
.make_impact_single_top_btm img{width: 50px;}
.make_impact_sec_new_head{margin-bottom: 25px;}
.make_impact_sec_new_inner_right {top: 0px;}
.make_impact_single_top{right: -150px;}
.make_impact_single_top:before {width: 17px;height: 17px;left: 80px;}
.make_impact_single_top_right{top: 30px;left: 120px;}
.make_impact_single_top_right:before{width: 15px;height: 15px;right: 100px;top: 75px;}
.make_impact_single_top_left{right: 20px;top: 0px;}
.make_impact_single_top_left:before {width: 14px;height: 14px;left: 90px;top: 0;}
.make_impact_single_top_btm {right: 10px;top: 125px;}
.make_impact_single_top_btm:before {width: 18px;height: 18px; left: 55px;top: -20px;}
.make_impact_sec_new_inner_left p{font-size: 12px;margin-bottom: 15px;}
.make_impact_sec_new_inner_left a {padding: 7px 25px; font-size: 14px;}
.why_choose_sec_inf:before{top: -20px;width: 56px;height: 70px}
.why_choose_sec_inf:after {right: 0;bottom: 0;width: 56px;height: 70px;}
.why_choose_sec_inf{padding: 15px 0;}
.why_choose_sec_inf_inner_head h6{font-size: 18px;}
.why_choose_sec_inf_inner_head{margin: 0 0 20px 0;}
.why_choose_sec_inf_inner_body {grid-template-columns: 1fr; grid-gap: 20px;align-items: center;}
.why_choose_sec_inf_inner_body_left {grid-template-columns: 1fr 1fr; grid-gap: 15px;}
.why_choose_sec_single_cont h5 {font-size: 10px;padding: 2px 15px;}
.why_choose_sec_inf_inner_body_right_single {padding: 20px;margin-bottom: 20px;}
.why_choose_sec_inf_inner_body_right_single h5{font-size: 12px;margin: 0 0 5px 0;}
.why_choose_sec_inf_inner_body_right_single p{font-size: 10px;}
.why_choose_sec_inf_inner_body_right_single span {width: 28px;height: 28px;font-size: 16px;left: -5px;top: -5px;}
.explore_sec_new_des{padding: 20px 0;}
.explore_sec_new_des_head h6{font-size: 20px;line-height: inherit;}
.explore_sec_new_des_head p{font-size: 14px;}
.explore_sec_new_des_head{margin-bottom: 20px;}
.explore_sec_new_des:before{display: none;}
.explore_sec_new_des:after{display: none;}
#exp_scrl .owl-nav .owl-prev{height: 30px;width: 30px;}
#exp_scrl .owl-nav .owl-next{height: 30px;width: 30px;}
#exp_scrl .owl-nav{left: 0px;width: 100%;}
.explore_sec_new_des_inner_single_cont h5{font-size: 14px;}
.explore_sec_new_des_inner_single_cont ul li{font-size: 12px;}
.hire_btn_new_exp{margin: 25px auto 0;}
.succes_story_new_sec_des:before{display: none;}
.succes_story_new_sec_des{padding: 15px 0;}
.succes_story_new_sec_des_head h6{font-size: 25px;}
.succes_story_new_sec_des_head{margin-bottom: 15px;}
.succes_story_new_sec_des_head p{font-size: 12px;width: 100%;}
.succes_story_new_sec_des_inner_body{grid-template-columns: 1fr 1fr;}
.succes_story_new_sec_des:after{display: none;}
.impact_sec_new_feed:before{display: none;}
.impact_sec_new_feed:after{display: none;}
.impact_sec_new_feed{padding: 20px 0;}
.impact_sec_new_feed_inner_head h6{font-size: 18px;line-height: inherit;}
.impact_sec_new_feed_inner_head{margin-bottom: 25px;}
.impact_sec_new_feed_inner_body{width: 100%;}
.impact_sec_new_feed_inner_single{display: block;}
.impact_sec_new_feed_inner_single_img img{width: 70%;}
.impact_sec_new_feed_inner_single_img h4{font-size: 14px;}
.impact_sec_new_feed_inner_single_cont{margin-top: 20px;text-align: center;}
.impact_sec_new_feed_inner_single_cont p {font-size: 14px;margin-bottom: 5px;}
.impact_sec_new_feed_inner_single_cont span i{font-size: 10px;}
#feed_sl .owl-nav {left: auto;right: 0; top: -15px;width: 20%;}
#feed_sl .owl-nav .owl-prev{width: 20px;height: 20px;}
#feed_sl .owl-nav .owl-next{width: 20px;height: 20px;}
.impact_sec_new_feed_inner_single_cont p:after{width: 100px;height: 50px;background-size: contain;}
.our_camp_succes{padding: 30px 0;}
.our_camp_succes_head h6{font-size: 20px;line-height: inherit;}
.our_camp_succes_head{margin-bottom: 25px;}
.our_camp_succes_inner_body { grid-template-columns: 1fr;grid-gap: 30px;}
.camp_cont_sec h6{font-size: 18px;}
.camp_cont_sec h5 {font-size: 12px;margin-bottom: 8px;}
.folo_sec { right: -35px;width: 60px; height: 60px;font-size: 8px;}
.limited_time_only_sec{padding: 0 0 15% 0;}
.limited_time_only_sec_inner {display: flex;grid-template-columns: 1fr;grid-gap: 30px;width: 100%;flex-direction: column-reverse;}
.png_1{width: 120px;}
.png_2 {width: 150px;left: 90px;top: 110px;}
.you_img {bottom: -12px;left: 0;width: 25px;}
.limited_time_only_sec_inner_right h6 {font-size: 25px;margin: 0 0 10px 0;}
.limited_time_only_sec_inner_right p{font-size: 12px;}
.limited_time_only_sec_inner_right_btm {grid-template-columns: 1fr;grid-column-gap: 10px;margin-top: 10px;}
.limited_time_only_sec_inner_right_single{margin-bottom: 15px;}
.limited_time_only_sec_inner_right_single label{font-size: 14px;}
.limited_time_only_sec_inner_right_single input{font-size: 10px;}
.limited_time_only_sec_inner_right_single textarea{font-size: 10px;}
.submit_btn_table_sec button {font-size: 14px;padding: 5px 30px;}
.instag_img {width: 35px;left: 100px;top: -20px;}
.tik_tok {bottom: -150px;width: 40px;right: 0;}
.limited_time_only_sec_inner_left:after{display: none;}

.e_comm_sec_new_inner .close {width: 15px;height: 15px;right: 15px;top: 10px;}
.e_comm_sec_new_inner{padding: 25px 20px 0 20px;}
.e_comm_sec_new_inner_slide .owl-next{width: 10px;height: 20px;}
.e_comm_sec_new_inner_slide .owl-prev{width: 10px;height: 20px;}
.e_comm_sec_new .modal-dialog {max-width: 100%;height: 280px;}
.e_comm_sec_new_inner_slide .owl-nav{left: -12px;}


.influencer_sign_up_head {padding: 10px 0 15px 0;}
.influencer_sign_up_head_logo {width: 140px;margin-bottom: 10px;}
.influencer_sign_up_head_main_text{gap:10px;}
.influencer_sign_up_head_main_text h6{font-size: 14px;}
.influencer_sign_up_head_cont_sec h5{font-size: 12px;}
.influencer_sign_up_head_cont_sec h4 {font-size: 32px;margin: 0 0 10px 0;line-height: inherit;}
.influencer_sign_up_head_cont_sec a {font-size: 12px;padding: 6px 25px;}
.mobile_sign_sec_video_single:nth-child(2) video {width: 140px;height: 315px;}
.mobile_sign_sec_video_single:nth-child(1) video {width: 85px;height: 260px;z-index: 1;}
.mobile_sign_sec_video_single:nth-child(3) video{width: 85px;height: 260px;right:25px;}
.mobile_sign_sec_video{left: 10px;margin: 15px 0 10px 0;}
.yt_sec {top: 15px;left: -25px;width: 40px;}
.insta_sec {width: 40px;top: 0;right: -20px;}
.snap_sec {left: 15px;width: 30px;}
.pin_sec {right: 30px;width: 25px;}
.mobile_sign_sec p {font-size: 16px;margin-bottom: 15px;line-height: inherit;}
.mobile_sign_sec a {font-size: 12px;padding: 6px 25px;}
.unlock_sec_new{padding: 15px 0 0 0;}
.unlock_sec_new_inner {grid-template-columns: 1fr;grid-gap: 30px;}
.hash_img{display: none;}
.unlock_sec_new_inner_left h6 {font-size: 18px;margin-bottom: 15px;text-align: center;}
.unlock_sec_new_inner_left p {font-size: 15px;margin-bottom: 15px;}
.unlock_sec_new_inner_left a {font-size: 12px;padding: 6px 22px;}
.start_journey_infl{padding: 30px 0 20px 0;}
.start_journey_infl_inner_head h6{font-size: 20px;}
.start_journey_infl_inner_head{margin-bottom: 15px;}
.start_journey_infl_inner_body{display: block;}
.start_journey_infl_left h6 {font-size: 14px;margin-bottom: 15px;line-height: inherit;text-align: center;}
.start_journey_infl_left{text-align: center;margin-bottom: 20px;}
.start_journey_infl_left a {font-size: 14px;padding: 6px 36px;}
.j_adv_img{display: none;}
.new_levl_sec:before{display: none;}
.new_levl_sec:after{display: none;}
.influencer_flid_sec_new:before{display: none;}
.influencer_flid_sec_new:after{display: none;}
.start_journey_infl_right_top:nth-child(1) img{width: 115px;}
.start_journey_infl_right_top{text-align: right;}
.start_journey_infl_right_btm img{width: 100px;}
.jf_img {position: absolute; bottom: 0;right: 130px; width: 120px; top: 110px;}
.start_journey_infl:after{display: none;}
.pp_img_sec{display: none;}
.level_up_sec_new_inner_body_inner{top: 50px;}
.level_up_sec_new_inner_single_join:nth-child(1) {top: -60px;}
.level_up_sec_new_inner_single_join:nth-child(5) {top: -60px;}
.level_up_sec_new_inner_single_join:nth-child(2) {top: -30px;}
.level_up_sec_new_inner_single_join:nth-child(4) {top: -30px;}
.influencer_flid_sec_new{padding: 20px 0;}
.influencer_flid_sec_new_head h6{font-size: 20px;}
.influencer_flid_sec_new_head p{font-size: 14px;}
.influencer_flid_sec_new_head{margin-bottom: 20px;}
.influencer_flid_sing_1{width: 100%;}
.influencer_flid_sing_2 {width: 100%;margin: 0 0 15px 0;}
.view_cate_btn_new{display: none;}
.influencer_flid_sec_new_inner_body{display: block;}
.influencer_flid_sing_3{width: 100%;margin: 0 0 15px auto;}
.influencer_flid_sing_4{width: 100%;}
.influencer_flid_sing_5 {width: 312px;margin: 0 0 15px auto;}
.influencer_flid_sing_6{width: 100%;right: 0px;}
.influencer_flid_sing_7{width: 100%;}

.influencer_flid_sing_1 h5{font-size: 14px;}
.influencer_flid_sing_2 h5{font-size: 14px;}
.influencer_flid_sing_3 h5{font-size: 14px;}
.influencer_flid_sing_4 h5{font-size: 14px;}
.influencer_flid_sing_5 h5{font-size: 14px;}
.influencer_flid_sing_6 h5{font-size: 14px;}
.influencer_flid_sing_7 h5{font-size: 14px;}
.new_sing_hir .why_choose_sec_inf_inner_head h6{font-size: 18px;}
.new_sing_hir.why_choose_sec_inf:before{display: none;}
.new_sing_hir.why_choose_sec_inf:after{display: none;}
.new_sing_hir .define_you_budget_body_sec_new_left_single {padding: 0 15px;}
.new_sing_hir .define_you_budget_body_sec_new_left_single p{font-size: 10px;}
.new_sing_hir .define_you_budget_body_sec_new_left_single h3{font-size: 9px;}
.new_sing_hir .define_you_budget_body_sec_new_left_single{height: 100px;}
.join_newtwork_infl {padding: 0 0 20px 0;}
.join_newtwork_infl_inner_head h6{font-size: 23px;}
.join_newtwork_infl_inner_head p{font-size: 16px;}
.join_newtwork_infl_inner_body{grid-template-columns: 1fr;}
.join_newtwork_infl_inner_body_left{width: 100%;padding: 15px;}
.join_newtwork_infl_inner_body_left h6{margin-bottom: 11px;}
.join_newtwork_infl_inner_body_left h4{margin-bottom: 22px;}
.submit_btn_new a{font-size: 14px;}
.join_newtwork_infl_inner_body_left_single input {font-size: 12px;height: 35px;}
.join_newtwork_infl_inner_body_right{width: 100%;}
.join_newtwork_infl_inner_body_left h5 { font-size: 12px; margin: 0 0 15px 0;}




.stand_up_includes_sec_head{margin-bottom: 10px;}
.stand_up_includes_sec_head h6{font-size: 18px;text-align: left;}
.stand_up_includes_sec_head h6:after{display: none;}
.stand_up_includes_sec_body_sec{grid-template-columns: 1fr;grid-gap: 25px;margin-top: 15px;}
.standup-comedy-service-includes-left-book-now-container{gap:18px;}
.stand_up_includes_sec:before{display: none;}
.stand_up_includes_sec:after{display: none;}
.standup-comedy-service-includes-iocn img {width: 30px;}
.standup-comedy-service-includes-left-book-now-folder{gap:18px;}
.standup-comedy-service-includes-left-book-now-folder h2 {font-size: 10px;line-height: inherit; width: 100%;}
.stand_up_includes_sec_head h6:after { width: 60px;height: 70px;right: 16px;top: -35px;transform: rotate(25deg);}
.book_tank_btn { margin: 15px 0 0 0;}
.book_tank_btn a { padding: 8px 20px; font-size: 14px;}
.smile_img{display: none;}
.smail_img{display: none;}
.stand_up_includes_sec_body_sec_right:after {width: 250px;height: 250px; right: 0; top: 0;}
.stand_up_includes_sec { padding: 15px 0 20px 0;}
.stand_fea_sec_new:after{display: none;}
.stand_fea_sec_new:before{display: none;}
.stand_fea_sec_new_inner_body:before{display: none;}
.stand_fea_sec_new_inner_body {grid-template-columns: 1fr;grid-gap: 15px;margin: 15px 0;}
.stand_fea_sec_new_single_cont h6{font-size: 18px;}
.stand_fea_sec_new_single_cont p{font-size: 14px;}
.play_cin_blue_sec span i{font-size: 13px;}
.play_cin_blue_sec p{font-size: 14px;}
.stand_fea_sec_new_single:hover .play_cin_blue_sec {width: 100px;height: 35px;}
.book_now_sec_stand { padding: 20px 0;}
.book_now_sec_stand_inner { grid-template-columns: 1fr; grid-gap: 20px;}
.book_now_sec_stand .stand_up_includes_sec_head{display: none;}
.book_now_form_esc_grid {grid-template-columns: 1fr;grid-gap: 15px;margin-bottom: 15px;}
.book_now_form_esc_grid_single{margin-bottom: 15px;}
.book_now_form_esc_grid .book_now_form_esc_grid_single{margin-bottom: 0px;}
.book_now_form_esc_grid_single label{font-size: 14px;}
.book_now_form_esc_grid_single input {width: 100%;height: 35px;font-size: 12px;}
.book_now_form_esc_grid_single textarea{font-size: 12px;}
.stand_up_come_header_logo {padding: 15px 0 0 0;width: 120px;}
.stand_up_come_right_cont_sec {position: absolute;right: -15px;bottom: 0px;}
.stand_up_come_header_right_icn{gap:0px;}
.stand_up_come_header{height: 260px;}
.stand_up_come_header_right_icn{top: 20px;}
.stand_up_come_left_cont_sec { flex: 0 0 150px;width: 150px;}
.stand_up_come_right_cont_sec {position: absolute;right: -15px;bottom: -10px;width: 125px;}
.stand_up_come_left_cont_sec h6 {font-size: 14px; margin-bottom: 5px;line-height: inherit;}
.stand_up_come_left_cont_sec span {font-size: 11px;padding: 8px 15px 6px 15px;margin-bottom: 8px;}
.stand_up_come_left_cont_sec p { font-size: 10px; margin: 0 0 10px 0;line-height: inherit;}
.stand_up_come_left_cont_sec a {padding: 10px 15px 7px 15px;font-size: 10px;line-height: 15px;}









}

@media only screen and (min-width:500px) and (max-width:767px) {

/*.Professional_photo_shoot_sec_inner .row .col-lg-6 {-ms-flex: 0 0 50%;flex: 0 0 50%;max-width: 50%;}*/
.get_your_products {right: 100px; bottom: 60px;*transform: rotate(25deg);}
.wave_slide1 { top: 5px;width: 10%;right: 5px;}
.upper_arrow {width: 150px; top: -40px; right: 45%;transform: rotate(15deg);}
.limited_time_sec_main {grid-template-columns: 1fr 1fr;grid-gap: 15px;}
.happy_clients_sec_main_body {grid-template-columns: 1fr 1fr 1fr 1fr;grid-gap: 20px;}
.category_sec_new_design_body {grid-template-columns: 1fr 1fr 1fr 1fr;grid-gap: 15px;}
.features_singers_sec_inner {grid-template-columns: 1fr 1fr 1fr;grid-gap: 20px;}

.unevil_sec_new_des_inner_head h6{font-size: 20px;}
.swiper-slide-active img {transform: scale(1.1) !important;}
.swiper-container-horizontal>.swiper-pagination-bullets{bottom: -55px;}
.review_sec_new_list{margin-top: 140px;}
.review_sec_new_list_single p{font-size: 16px;}
.photoshoot_inceludes_single {grid-template-columns: 1fr 1fr;grid-gap: 15px;}
.new_align_box{flex-direction: inherit;display: grid;top: 60px;}
.new_bag{right: 0px;}
.shoe_img { width: 100px !important; bottom: -30px; right: 0; left: 40%;}
.cup_tea { position: absolute;right: 35%; top: -30px;width: 110px !important; z-index: 11;}
.photoshoot_inceludes_single_left_box ul li{font-size: 14px;}
.new_btn_place{margin-top: 120px;}
.budget_sec_new_btn a{font-size: 14px;}
.product_shoot_sec_cate_inner_head h6{font-size: 20px;}
.product_shoot_sec_cate_inner_body {grid-template-columns: 1fr 1fr;}
.budget_sec_new_sec_inner_body ul{display: flex;}
.budget_sec_new_sec_inner_body ul li{margin: 0px;}
.shhots_for_sec_new_inner_body{display: flex;}
.shhots_for_sec_single { display: block; width: 100%;margin: 0;}
.limited_time_only_inner {grid-template-columns: 1fr 1fr;grid-gap: 50px;}



.video_sec_image{position: relative;display: none;}
.video_sec_image img{width: 100%;}
.video_sec_vid{display: block;}
.influ_banner_cont_sec_inner_cont{top: 220px;left: 50px;}
.get_start_btn_more{margin: inherit;}
.influencers_video_sec_body{width: 70%;}
.define_you_budget_body_sec_new {grid-template-columns: 1fr 1fr;grid-gap: 20px;width: 100%;}
.define_you_budget_body_sec_new_right {width: 50%;margin: 0 auto;}
.make_impact_sec_new_inner_body{display: grid;align-items: center;}
.make_impact_sec_new_inner_left{text-align: left;}
.make_impact_single_top{right: 30px;}
.make_impact_single_top:before {left: 100px;top: 100px;}
.make_impact_single_top_right {top: 30px;left: 0px;}
.make_impact_single_top_left:before{left: 85px;}
.make_impact_single_top_left {right: 150px;top: 80px;}
.make_impact_single_top_btm {right: 145px;top: 170px;}
.make_impact_single_top_btm:before { width: 12px; height: 12px; left: -30px; top: 25px;}
.why_choose_sec_inf_inner_body {grid-template-columns: 1fr 1fr;}
.why_choose_sec_inf_inner_body_left {grid-template-columns: 1fr 1fr 1fr;}
.explore_sec_new_des_inner_single_cont{margin: 5px 0 0 0;}
.succes_story_new_sec_des_inner_body {grid-template-columns: 1fr 1fr 1fr 1fr;}
.impact_sec_new_feed_inner_single {display: flex;}
#feed_sl .owl-nav .owl-prev{width: 35px;height: 35px;}
#feed_sl .owl-nav .owl-next{width: 35px;height: 35px;}
.our_camp_succes_inner_body{grid-template-columns: 1fr 1fr 1fr;}
.limited_time_only_sec_inner {display: grid;grid-template-columns: 1fr 1fr;}
.limited_time_only_sec_inner_right_btm {grid-template-columns: 1fr 1fr;}
.you_img{bottom: 225px;}
.limited_time_only_sec {padding: 0 0 5% 0;}
.tik_tok {bottom: 80px;right: 70px;width: 40px;}


.e_comm_sec_new .modal-dialog{height: 480px;}
.mobile_sign_sec_video_single:nth-child(2) video{width: 180px;}
.mobile_sign_sec_video_single:nth-child(1) video{width: 110px;}
.mobile_sign_sec_video_single:nth-child(3) video { width: 140px; height: 260px; right: 35px;}
.unlock_sec_new_inner{grid-template-columns: 1fr 1fr;}
.unlock_sec_new_inner_left h6{text-align: left;}
.start_journey_infl_inner_body{display: flex;}
.start_journey_infl_left{flex: 0 0 310px;text-align: left;}
.start_journey_infl_left h6{text-align: left;}
.start_journey_infl_right_top:nth-child(1) img{width: 330px;}
.start_journey_infl_right_btm img{width: 160px;}
.jf_img { right: 350px; width: 330px; top: 200px;}
.level_up_sec_new_inner_body_inner{top: 80px;}

.level_up_sec_new_inner_single_join:nth-child(1){top: -120px;}
.level_up_sec_new_inner_single_join:nth-child(5){top: -120px;}
.level_up_sec_new_inner_single_join:nth-child(4){top: -50px;}
.level_up_sec_new_inner_single_join:nth-child(2){top: -50px;}
.influencer_flid_sing_4 {width: 40%;}






}

@media only screen and (min-width:300px) and (max-width:450px) {

.stand_up_come_left_cont_sec { flex: 0 0 200px; width: 200px;}
.stand_up_come_left_cont_sec h6 {font-size: 17px; margin-bottom: 5px;line-height: inherit;}
.stand_up_come_left_cont_sec span {font-size: 14px;padding: 8px 15px 6px 15px;margin-bottom: 8px;}
.stand_up_come_left_cont_sec p { font-size: 12px; margin: 0 0 10px 0;line-height: inherit;}
.stand_up_come_left_cont_sec a {padding: 10px 15px 7px 15px;font-size: 12px;line-height: 15px;}
.stand_up_come_right_cont_sec{bottom: -12px;}
.stand_up_come_header_right_icn {top: 10px;}






}