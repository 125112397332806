@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap');
/*font-family: 'Raleway', sans-serif;*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
/*font-family: 'Poppins', sans-serif;*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
/*font-family: 'Roboto', sans-serif;*/

@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@300;400;500;600;700;800;900&display=swap');
/*font-family: 'Barlow Semi Condensed', sans-serif;*/

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/*font-family: 'Montserrat', sans-serif;*/


/*html {scroll-behavior: smooth;}*/
/*Rj Code*/

::selection {
	background:#0096C7;
	color:#FFF;
}

::-moz-selection {
	background:#0096C7;
	color:#FFF;
}

/*html {scroll-behavior: smooth;}*/
select::-ms-expand { display: none; }
img{max-width:100%;height:auto;}
a{  -webkit-transition: all .3s ease;-moz-transition: all .3s ease;-o-transition: all .3s ease;-ms-transition: all .3s ease;transition: all .3s ease;}
h1 {margin:0px 0px 30px 0px;padding:0px;line-height: 1.2;font-weight:600;}
h2 {margin:0px 0px 25px 0px;padding:0px;line-height: 1.2;font-weight:600;}
h3 {margin:0px 0px 25px 0px;padding:0px;line-height: 46px;font-weight:600;}
h4 {margin:0px 0px 25px 0px;padding:0px;line-height: 1.2;font-weight:600;}
h5 {margin:0px 0px 15px 0px;line-height: 25px;font-weight:600;}
h6 {margin:0px 0px 15px 0px;line-height: 1.1;font-weight:normal;}



.spacer1px {clear:both;line-height:0;font-size:0;overflow:hidden;height:1px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer5px {clear:both;line-height:0;font-size:0;overflow:hidden;height:5px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer15px {clear:both;line-height:0;font-size:0;overflow:hidden;height:15px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer10px {clear:both;line-height:0;font-size:0;overflow:hidden;height:10px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer20px {clear:both;line-height:0;font-size:0;overflow:hidden;height:20px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer30px {clear:both;line-height:0;font-size:0;overflow:hidden;height:30px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer50px {clear:both;line-height:0;font-size:0;overflow:hidden;height:50px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}
.spacer80px {clear:both;line-height:0;font-size:0;overflow:hidden;height:50px; background:none; margin:0px 0px 0px 0px;width:100%; border:none;}

body {
	overflow-x:hidden;
	color:#333333;
	font-size:16px;
	line-height:1.4; 
	margin:0px; 
	padding:0px; 
	outline:none;
	text-decoration:none;
	list-style:none;
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
	background-color:#fff;
	/*height: 100vh;*/
}

p{line-height:1.4;margin:0px 0px 20px 0px;font-family: 'Poppins', sans-serif;}
ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}
a:hover{ text-decoration:none;}
-webkit-input-placeholder {color: #a1a1a1 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
-moz-placeholder {color: #a1a1a1 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
-ms-input-placeholder {color: #a1a1a1 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
-moz-placeholder {color: #a1a1a1 !important; opacity:1 !important;font-family: 'Poppins', sans-serif;}
.form-control::-webkit-input-placeholder {color: #a1a1a1 !important; opacity:1 !important;}
.form-control::-moz-placeholder {color: #a1a1a1 !important; opacity:1 !important;}
.form-control:-ms-input-placeholder {color: #a1a1a1 !important; opacity:1 !important;}
.form-control:-moz-placeholder {color: #a1a1a1 !important; opacity:1 !important;}

:focus, button:focus {outline-style:none;box-shadow:none;border-color:transparent;}
*:focus {outline: none;}
input:focus{outline:none;}

.pagetitle{ position:relative; text-align:left; padding:0px 0px 70px 0px; }
.pagetitle h2{line-height: 1.1; font-size:51px;color:#fff;padding:0px; margin:0px 0px 0px 0px;}

img{max-width:100%;height:auto; outline:none;}
a{outline:none; border:none;}
.fl_w{float: left;}
.container-fluid{padding-left: 60px !important;padding-right: 60px !important;}
